<template>
  <div class="setbody">
    <div class="headersetting">
      <img
        src="../assets/img/seticon.png"
        alt="Settings Icon"
        width="24"
        height="24"
      />
      การตั้งค่าขอรับใบแจ้งหนี้และใบเสร็จ
    </div>

    <div class="content">
      <hr />
      <div class="item-link">
        <span class="span-link" @click="onClickShowPopupCancle">
          <p>
            <img
              src="../assets/img/bin_gray.png"
              alt="Delete Icon"
              width="24"
              height="24"
              class="image-cancel"
            />
            ยกเลิกบริการ SME Connect
          </p>
        </span>
      </div>
      <hr />
    </div>

    <div v-if="isPopupVisible" id="popup" class="popup">
      <div class="popup-content">
        <p class="black">
          หากท่านต้องการ
          <span color="red"><u>ยกเลิกบริการ</u></span>
          <br />
          SME Connect กรุณายืนยันคำสั่ง
        </p>
        <img src="../assets/img/test.png" alt="Send Icon" height="100" />
        <p class="small navy"><u>ขอรหัสใหม่ </u></p>
        <p class="black">พิมพ์ตัวเลขตามภาพ :</p>
        <input
          type="text"
          class="form-control normal"
          maxlength="6"
          inputmode="numeric"
        />
        <p class="small">
          <span>กรอกรหัสไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง</span>
        </p>
        <button class="btn btn-primary btn-block" @click="closePopup">
          ยืนยัน
        </button>
      </div>
    </div>
    <div
      v-if="isPopupVisible"
      id="overlay"
      class="overlay"
      @click="closePopup"
    ></div>

    <div class="fixed-footer">
      <!-- <Buttons
        :primary="primary"
        :label="label"
        @click="showPopup"
        :disabled="disable"
        :type="type"
      /> -->
    </div>

    <P10PopupCancelReceipt
      :showCancelPopup="showCancelPopup"
      @update:showCancelPopup="showCancelPopup = $event"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import P10PopupCancelReceipt from "./P10PopupCancelReceipt.vue";
import Buttons from "@/components/button/Buttons.vue";
import { useStore } from "vuex";
import { onPostSettings } from "@/services/member";
// import liff from "@line/liff";
// import { initializeLiff } from "@/services/liff";

const router = useRouter();

const store = useStore();

const route = useRoute();

const getCtk = computed(() => route.query.ctk || "");

const userInfo = computed(() => store.getters.getUserInfo || "");

const data_res_setting = ref({
  invoice: false,
  receipt: false,
  auto_send: false,
});

const invoiceChecked = ref<boolean>(true);
const receiptChecked = ref<boolean>(true);
const isPopupVisible = ref<boolean>(false);

const isChkSetting = ref<boolean>(false);

const setting_detail_display = ref("not_auto_sent");

function closePopup() {
  isPopupVisible.value = false;
}

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ปิด");

const disable = ref(false);

const type = ref("button");

///--------- button end

///------------start popup cancle

const showCancelPopup = ref<boolean>(false);

const onClickShowPopupCancle = () => {
  showCancelPopup.value = true;
};

///------------end popup cancle

onMounted(() => {
  onPostSettingsCallService();
});

const onPostSettingsCallService = () => {
  /// get data user setting
  let req = {
    context_token: getCtk.value !== "" ? getCtk : userInfo.value.context_token,
  };
  onPostSettings(req).then((res: any) => {
    if (res.context_token) {
      data_res_setting.value = {
        ...res.enable_documents,
      };
      let data_res = {
        context_token: res.context_token,
      };
      store.dispatch("updateUserInfo", data_res);
    } else {
    }
  });
};

const showPopup = () => {
  // router.push({ name: "P04SettingAutoSuccess" });
  // liff.closeWindow(); // ปิดแอปพลิเคชัน
  window.postMessage("closeWebView", "*");
  window.close();
    // router.push({ name: "P01Consent" });
};
// initializeLiff();
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

.span-link {
  cursor: pointer;
}

.check-box {
  background-color: #fff;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  margin: 0 0 0 0;
}
.fnt-text {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.item-link {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  font-size: 30px;
  margin-bottom: -11px;
  color: #7a7a7a;
}

.p-chck-item-true {
  background-color: #38b148;
  color: white;
  box-shadow: #ccc;
}

hr {
  margin: 0px;
}

label {
  margin-bottom: 0px;
}

.item-service-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}
.item-service {
  display: grid;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.item-div-checkbox {
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-bottom: 0px;
}

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
