<template>
  <div class="content">
    <div class="terms-content" ref="termsContent" @scroll="handleScroll">
      <span
        >การให้การยินยอมเพื่อรับบริการส่งใบแจ้งหนี้และใบเสร็จผ่าน SME Connect
        อัตโนมัติ</span
      >
      <p>
        มาตรการ และวิธีการรักษาความมั่นคงปลอดภัยเว็บไซต์
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทยได้ตระหนักถึงความสำคัญในการรักษาความมั่นคงปลอดภัยเว็บไซต์
        เพื่อปกป้องข้อมูลของผู้ใช้บริการจากการถูกทำลาย หรือบุกรุกจากผู้ไม่หวังดี
        หรือผู้ที่ไม่มีสิทธิ์ในการเข้าถึงข้อมูล
        จึงได้กำหนดมาตรการรักษาความมั่นคงปลอดภัยเว็บไซต์
        โดยใช้มาตรฐานการรักษาความปลอดภัยของข้อมูลขั้นสูง ด้วยเทคโนโลยี Secured
        Socket Layer (SSL) ซึ่งเป็นเทคโนโลยีในการเข้าสู่ข้อมูลผ่านรหัสที่ระดับ
        128 bits (128-bits Encryption)
        เพื่อเข้ารหัสข้อมูลที่ถูกส่งผ่านเครือข่ายอินเทอร์เน็ตในทุกครั้ง
        ที่มีการทำธุรกรรมทางการเงินผ่านเครือข่ายอินเทอร์เน็ตของ
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทยทำให้ผู้ที่ดักจับข้อมูลระหว่างทางไม่สามารถนำข้อมูลไปใช้ต่อได้
        โดยจะใช้การเข้ารหัสเป็นหลักในการรักษาความปลอดภัยของข้อมูล
        โดยผู้ใช้บริการสามารถสังเกตได้จากชื่อโปรโตคอลที่เป็น https://
      </p>
      <p>
        เทคโนโลยีเสริมที่นำมาใช้ในการรักษาความมั่นคงปลอดภัย <br />

        นอกจากมาตรการ
        และวิธีการรักษาความมั่นคงปลอดภัยโดยทั่วไปที่กล่าวข้างต้นแล้ว
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทยยังใช้เทคโนโลยีระดับสูงดังต่อไปนี้เพื่อปกป้องข้อมูลส่วนตัวของท่าน
      </p>
      <p>
        – Firewall เป็นระบบซอฟท์แวร์ที่จะอนุญาตให้เฉพาะผู้ที่มีสิทธิ
        หรือผู้ที่ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย
        อนุมัติเท่านั้นจึงจะผ่าน Fire Wall เพื่อเข้าถึงข้อมูลได้
      </p>
      <p>
        – Scan Virus
        นอกจากเครื่องคอมพิวเตอร์ทุกเครื่องที่ให้บริการจะมีการติดตั้ง Software
        ป้องกัน Virus ที่มีประสิทธิภาพสูงและ Update อย่างสม่ำเสมอแล้ว
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย ยังได้ติดตั้ง Scan
        Virus Software บนเครื่อง Server โดยเฉพาะอีกด้วย
      </p>
      <p>
        – Cookies เป็นไฟล์คอมพิวเตอร์เล็กๆ ที่จะทำการเก็บข้อมูลชั่วคราวที่จำเป็น
        ลงในเครื่องคอมพิวเตอร์ของผู้ขอใช้บริการ เพื่อความสะดวกและรวดเร็วใน
        การติดต่อสื่อสาร อย่างไรก็ตาม
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย
        ตระหนักถึงความเป็นส่วนตัวของ ผู้ใช้บริการเป็นอย่างดี จึงหลีกเลี่ยงการใช้
        Cookies แต่ถ้าหากมีความจำเป็น ต้องใช้ Cookies
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย จะพิจารณาอย่างรอบคอบ
        และตระหนักถึงความปลอดภัย และความเป็นส่วนตัวของผู้ขอรับบริการเป็นหลัก
      </p>
      <p>
        – Auto Log off
        ในการใช้บริการของธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย
        หลังจากเลิกการใช้งานควร Log off ทุกครั้ง กรณีที่ผู้ใช้บริการลืม Log off
        ระบบจะทำการ Log off ให้โดยอัตโนมัติภายในเวลาที่เหมาะสมของแต่ละบริการ
        ทั้งนี้เพื่อความปลอดภัยของผู้ใช้บริการเอง
        <br />
      </p>
      <p>
        ข้อแนะนำเกี่ยวกับการรักษาความมั่นคงปลอดภัย <br />
        แม้ว่า ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย
        จะมีมาตรฐานเทคโนโลยีและวิธีการทางด้านการรักษาความปลอดภัยอย่างสูง
        เพื่อช่วยมิให้มีการเข้าสู่ข้อมูลส่วนตัวหรือข้อมูลที่เป็นความลับของท่านโดยปราศจากอำนาจตามที่กล่าว
        ข้างต้นแล้วก็ตาม แต่ก็เป็นที่ทราบกันอยู่โดยทั่วไปว่า
        ปัจจุบันนี้ยังมิได้มีระบบ รักษาความปลอดภัยใดๆ
        ที่จะสามารถปกป้องข้อมูลของท่านได้อย่างเด็ดขาดจากการถูกทำลายหรือถูกเข้าถึงโดยบุคคลที่ปราศจากอำนาจได้
        ดังนั้นท่านจึงควรปฏิบัติตามข้อแนะนำเกี่ยวกับการรักษาความมั่นคงปลอดภัยดังต่อไปนี้ด้วยคือ
      </p>
      <p>
        – ระมัดระวังในการ Download Program จาก Internet มาใช้งาน ควรตรวจสอบ
        Address ของเว็บไซต์ให้ถูกต้องก่อน Login
        เข้าใช้บริการเพื่อป้องกันกรณีที่มีการปลอมแปลงเว็บไซต์
      </p>
    </div>
    <div class="fixed-footer">
      <Buttons
        :primary="primary"
        :label="label"
        @click="goToPage"
        :disabled="!canAccept"
        :type="type"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ยอมรับ");

const isAgreed = ref<boolean>(false);

const type = ref("button");

///--------- button end

///--------- scroll bar start

const termsContent = ref<HTMLElement | null>(null);
const canAccept = ref(false);

const handleScroll = () => {
  if (termsContent.value) {
    const { scrollTop, scrollHeight, clientHeight } = termsContent.value;
    // canAccept.value = scrollTop + clientHeight + 1 >= scrollHeight;
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      canAccept.value = true;
    } else {
      canAccept.value = false;
      isAgreed.value = false;
    }
  }
};

///--------- scroll bar end

const goToPage = () => {
  if (canAccept.value) {
    router.push({ name: "P09AgeeReceipt" });
  }
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

span {
  text-align: center;
  font-size: 1.5rem;
}

p {
  text-align: left;
  font-size: 1rem;
}

.consent-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.terms-content {
  height: 530px;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 10px;
}

.actions {
  display: flex;
  align-items: center;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
