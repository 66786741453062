import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/img/seticon.png'
import _imports_1 from '../assets/img/bin_gray.png'
import _imports_2 from '../assets/img/test.png'


const _hoisted_1 = { class: "setbody" }
const _hoisted_2 = {
  key: 0,
  id: "popup",
  class: "popup"
}

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import P10PopupCancelReceipt from "./P10PopupCancelReceipt.vue";
import Buttons from "@/components/button/Buttons.vue";
import { useStore } from "vuex";
import { onPostSettings } from "@/services/member";
// import liff from "@line/liff";
// import { initializeLiff } from "@/services/liff";


export default /*@__PURE__*/_defineComponent({
  __name: 'P06Settings_V2_setting_Cancel',
  setup(__props) {

const router = useRouter();

const store = useStore();

const route = useRoute();

const getCtk = computed(() => route.query.ctk || "");

const userInfo = computed(() => store.getters.getUserInfo || "");

const data_res_setting = ref({
  invoice: false,
  receipt: false,
  auto_send: false,
});

const invoiceChecked = ref<boolean>(true);
const receiptChecked = ref<boolean>(true);
const isPopupVisible = ref<boolean>(false);

const isChkSetting = ref<boolean>(false);

const setting_detail_display = ref("not_auto_sent");

function closePopup() {
  isPopupVisible.value = false;
}

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ปิด");

const disable = ref(false);

const type = ref("button");

///--------- button end

///------------start popup cancle

const showCancelPopup = ref<boolean>(false);

const onClickShowPopupCancle = () => {
  showCancelPopup.value = true;
};

///------------end popup cancle

onMounted(() => {
  onPostSettingsCallService();
});

const onPostSettingsCallService = () => {
  /// get data user setting
  let req = {
    context_token: getCtk.value !== "" ? getCtk : userInfo.value.context_token,
  };
  onPostSettings(req).then((res: any) => {
    if (res.context_token) {
      data_res_setting.value = {
        ...res.enable_documents,
      };
      let data_res = {
        context_token: res.context_token,
      };
      store.dispatch("updateUserInfo", data_res);
    } else {
    }
  });
};

const showPopup = () => {
  // router.push({ name: "P04SettingAutoSuccess" });
  // liff.closeWindow(); // ปิดแอปพลิเคชัน
  window.postMessage("closeWebView", "*");
  window.close();
    // router.push({ name: "P01Consent" });
};
// initializeLiff();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "headersetting" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Settings Icon",
        width: "24",
        height: "24"
      }),
      _createTextVNode(" การตั้งค่าขอรับใบแจ้งหนี้และใบเสร็จ ")
    ], -1)),
    _createElementVNode("div", { class: "content" }, [
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", { class: "item-link" }, [
        _createElementVNode("span", {
          class: "span-link",
          onClick: onClickShowPopupCancle
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Delete Icon",
              width: "24",
              height: "24",
              class: "image-cancel"
            }),
            _createTextVNode(" ยกเลิกบริการ SME Connect ")
          ], -1)
        ]))
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1))
    ]),
    (isPopupVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", { class: "popup-content" }, [
            _cache[4] || (_cache[4] = _createStaticVNode("<p class=\"black\" data-v-b46f191c> หากท่านต้องการ <span color=\"red\" data-v-b46f191c><u data-v-b46f191c>ยกเลิกบริการ</u></span><br data-v-b46f191c> SME Connect กรุณายืนยันคำสั่ง </p><img src=\"" + _imports_2 + "\" alt=\"Send Icon\" height=\"100\" data-v-b46f191c><p class=\"small navy\" data-v-b46f191c><u data-v-b46f191c>ขอรหัสใหม่ </u></p><p class=\"black\" data-v-b46f191c>พิมพ์ตัวเลขตามภาพ :</p>", 4)),
            _cache[5] || (_cache[5] = _createElementVNode("input", {
              type: "text",
              class: "form-control normal",
              maxlength: "6",
              inputmode: "numeric"
            }, null, -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "small" }, [
              _createElementVNode("span", null, "กรอกรหัสไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง")
            ], -1)),
            _createElementVNode("button", {
              class: "btn btn-primary btn-block",
              onClick: closePopup
            }, " ยืนยัน ")
          ])
        ]))
      : _createCommentVNode("", true),
    (isPopupVisible.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: "overlay",
          class: "overlay",
          onClick: closePopup
        }))
      : _createCommentVNode("", true),
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "fixed-footer" }, null, -1)),
    _createVNode(P10PopupCancelReceipt, {
      showCancelPopup: showCancelPopup.value,
      "onUpdate:showCancelPopup": _cache[0] || (_cache[0] = ($event: any) => (showCancelPopup.value = $event))
    }, null, 8, ["showCancelPopup"])
  ]))
}
}

})