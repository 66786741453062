<template>
  <div v-if="isVisible" class="popup-overlay" @click="close">
    <div class="popup-content" @click.stop>
      <!-- <span class="popup-close" @click="close">&times;</span> -->
      <div class="popup-content">
        <!-- <p class="black">OTP ไม่ถูกต้อง</p> -->
        <p class="black">{{ headText }}</p>
        <p class="small grey">{{ message }}</p>
      </div>
      <button class="btn btn-primary btn-block" @click="close">ปิด</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps<{
  isVisible: boolean;
  headText: string;
  message: string;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
