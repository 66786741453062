import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/check-icon.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "fixed-footer" }

import Buttons from "@/components/button/Buttons.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'P09AgeeReceipt',
  setup(__props) {

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block btnfoot");

const label = ref("ปิด");

const isAgreed = ref<boolean>(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  router.push("/");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"status-icon\" data-v-40298458><img src=\"" + _imports_0 + "\" alt=\"Check Icon\" width=\"100px\" height=\"100px\" data-v-40298458></div><h2 class=\"green\" data-v-40298458>ดำเนินการเรียบร้อยแล้ว</h2><p class=\"small\" data-v-40298458> บริการใบแจ้งหนี้และใบเสร็จ ผ่าน SME Connect อัตโนมัติจะมีผลภายใน 1-2 วัน </p><p class=\"small\" data-v-40298458>ขอบพระคุณที่ให้การสนับสนุน ลดการใช้กระดาษ</p><p class=\"small\" data-v-40298458>เพื่อรวมเป็นส่วนหนึ่งของการลดภาวะโลกร้อน</p>", 5)),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "txt-footer" }, [
          _createElementVNode("p", null, "หากต้องการยกเลิกบริการดังกล่าว"),
          _createElementVNode("a", { href: "tel:1357" }, "กรุณาติดต่อ SME D Bank Call Center โทร.1357")
        ], -1)),
        _createElementVNode("div", null, [
          _createVNode(Buttons, {
            primary: primary.value,
            label: label.value,
            onClick: handleClose,
            disabled: isAgreed.value,
            type: type.value
          }, null, 8, ["primary", "label", "disabled", "type"])
        ])
      ])
    ])
  ]))
}
}

})