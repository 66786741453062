<template>
  <div v-if="showOtpPopup" class="otp-popup" @click="close">
    <div class="otp-content">
      <div>
        <div>
          <p>รหัส OTP ของคุณคือ</p>
        </div>
        <div>
          <p class="text-otp">{{ otp }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  showOtpPopup: boolean;
  otp: string;
}>();

// สร้างฟังก์ชัน emit
const emit = defineEmits<{
  (event: "update:showOtpPopup", showOtpPopup: boolean): void;
}>();

// ฟังก์ชันสำหรับเพิ่มค่าและส่งค่ากลับไปยัง component พ่อแม่
const close = () => {
  const newValue = false;
  emit("update:showOtpPopup", newValue);
};
</script>

<style scoped>
.otp-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

p{
  font-size: 2rem;
}
.text-otp{
  font-size: 4rem;
}
</style>
