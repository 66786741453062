import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "popup-content" }
const _hoisted_2 = { class: "black" }
const _hoisted_3 = { class: "small grey" }




export default /*@__PURE__*/_defineComponent({
  __name: 'PopUp',
  props: {
    isVisible: { type: Boolean },
    headText: {},
    message: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const close = () => {
  emit("close");
};

return (_ctx: any,_cache: any) => {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "popup-overlay",
        onClick: close
      }, [
        _createElementVNode("div", {
          class: "popup-content",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.headText), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1)
          ]),
          _createElementVNode("button", {
            class: "btn btn-primary btn-block",
            onClick: close
          }, "ปิด")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})