// src/components/Loading.vue
<template>
  <div class="loading-container">
    <div class="spinner"></div>
    <!-- <p class="moving-text">กรุณารอสักครู่...</p> -->
    <p class="moving-text">
      กรุณารอสักครู่...
     
    </p>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";


const text = "...";
const textArray = ref(text.split(''));
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7); /* ทำให้โปร่งใสเล็กน้อย */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* ทำให้หน้าจออยู่ด้านหน้าสุด */
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7); /* ทำให้โปร่งใสเล็กน้อย */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* ทำให้หน้าจออยู่ด้านหน้าสุด */
}

.spinner {
  border: 4px solid rgba(22, 28, 175, 0.1);
  border-left-color: #161caf;
  color: #161caf;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.moving-text span {
  display: inline-block;
  font-size: 1.2rem;
  color: #161caf;
  animation: move 1.5s ease-in-out infinite;
}

/* Animation สำหรับแต่ละตัวอักษร */
@keyframes move {
  0%, 100% {
    transform: translateY(0); /* ตำแหน่งปกติ */
  }
  50% {
    transform: translateY(-10px); /* ขยับขึ้น */
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
