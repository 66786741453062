import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "otp-content" }
const _hoisted_3 = { style: {"font-size":"50","background-color":"#ccc","color":"black"} }
const _hoisted_4 = { class: "input-group input-group-cancel" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  class: "text-danger",
  style: {"font-size":"20px"}
}

import Buttons from "@/components/button/Buttons.vue";
import { onPostRemoveSettings } from "@/services/member";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'P10PopupCancelReceipt',
  props: {
    showCancelPopup: { type: Boolean }
  },
  emits: ["update:showCancelPopup"],
  setup(__props: any, { emit: __emit }) {

const count_gener_input = ref(0);

const isLoading = ref(true);

const isDisableBtn = ref(false);

///--------- button start

const primary = ref("btn btn-primary btn-block");

const label = ref("ยืนยัน");

const type = ref("button");

///--------- button end

const props = __props;

const emit = __emit;

const router = useRouter();

const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo);

const numberCancle = ref(generateOtp());
const userInput = ref("");
const chkNumberGener = ref<boolean>(false);

function generateOtp() {
  return Math.floor(100000 + Math.random() * 900000).toString();
}

function requestNewOtp() {
  numberCancle.value = generateOtp();
  count_gener_input.value = 0;
  userInput.value = "";
}

const onClickComfirm = () => {
  if (userInput.value === numberCancle.value) {
    isDisableBtn.value = true;
    onPostRemoveSettingsCallService();
  } else {
    count_gener_input.value += 1;
    chkNumberGener.value = true;
  }
};

const onPostRemoveSettingsCallService = () => {
  // remove data user setting
  let req = {
    context_token: userInfo.value.context_token,
  };
  isLoading.value = true;
  onPostRemoveSettings(req).then(
    (res: any) => {
      isLoading.value = false;
      isDisableBtn.value = false;
      if (res.is_done === true) {
        const newValue = false;
        emit("update:showCancelPopup", newValue);
        router.push({ name: "P11SeccessCancelReceipt" });
      } else {
      }
    },
    function (err: any) {
      isLoading.value = false;
      isDisableBtn.value = false;
      console.log("Message error", err);
      router.push({ name: "error" });
    }
  );
};

const onCheckLengthNumber = () => {
  return userInput.value.length === 6;
};

const close = () => {
  const newValue = false;
  emit("update:showCancelPopup", newValue);
};

return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!

  return (_ctx.showCancelPopup)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "popup-overlay",
        onClick: close
      }, [
        (isLoading.value)
          ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "popup-content",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "popup-content" }, [
              _createElementVNode("p", null, [
                _createTextVNode("หากท่านต้องการ"),
                _createElementVNode("u", { class: "text-danger" }, "ยกเลิกบริการ")
              ]),
              _createElementVNode("p", { style: {"line-height":"1.2em"} }, "SME Connect กรุณายืนยันคำสั่ง")
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(numberCancle.value), 1)
              ]),
              _createElementVNode("span", {
                style: {"cursor":"pointer"},
                onClick: requestNewOtp
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("p", { style: {"line-height":"1.2em"} }, [
                  _createElementVNode("u", { style: {"font-size":"20px","color":"blue"} }, "ขอรหัสใหม่")
                ], -1)
              ])),
              _cache[3] || (_cache[3] = _createElementVNode("p", { style: {"color":"black"} }, "พิมพ์ตัวเลขตามภาพ :", -1)),
              _createElementVNode("div", _hoisted_4, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userInput).value = $event)),
                  type: "text",
                  class: "form-control form-control-cancel",
                  placeholder: "กรอกตัวเลข",
                  maxlength: "6",
                  onInput: onCheckLengthNumber
                }, null, 544), [
                  [_vModelText, userInput.value]
                ])
              ]),
              (chkNumberGener.value && count_gener_input.value !== 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, " กรอกรหัสไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง " + _toDisplayString(count_gener_input.value) + " / 3 ", 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(Buttons, {
                primary: primary.value,
                label: label.value,
                onClick: onClickComfirm,
                disabled: 
              !onCheckLengthNumber() || count_gener_input.value === 3 || isDisableBtn.value
            ,
                type: type.value
              }, null, 8, ["primary", "label", "disabled", "type"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})