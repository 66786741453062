<template>
  <div class="content">
    <div class="status-icon">
      <img
        src="../assets/img/check-icon.png"
        alt="Check Icon"
        width="100px"
        height="100px"
      />
    </div>
    <h2 class="green">ดำเนินการเรียบร้อยแล้ว</h2>
    <p class="small">การขอรับใบแจ้งหนี้และใบเสร็จ จะมีผลภายใน 1-2 วัน</p>
    <!-- <div class="row align-items-end"> -->
      <Buttons
      :primary="primary"
      :label="label"
      @click="handleClose"
      :disabled="disable"
      :type="type"
    />
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ปิด");

const disable = ref(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  router.push("/");
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
