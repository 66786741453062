import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "otp-content" }
const _hoisted_2 = { class: "text-otp" }


export default /*@__PURE__*/_defineComponent({
  __name: 'OtpPopup',
  props: {
    showOtpPopup: { type: Boolean },
    otp: {}
  },
  emits: ["update:showOtpPopup"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// สร้างฟังก์ชัน emit
const emit = __emit;

// ฟังก์ชันสำหรับเพิ่มค่าและส่งค่ากลับไปยัง component พ่อแม่
const close = () => {
  const newValue = false;
  emit("update:showOtpPopup", newValue);
};

return (_ctx: any,_cache: any) => {
  return (_ctx.showOtpPopup)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "otp-popup",
        onClick: close
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _cache[0] || (_cache[0] = _createElementVNode("div", null, [
              _createElementVNode("p", null, "รหัส OTP ของคุณคือ")
            ], -1)),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.otp), 1)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})