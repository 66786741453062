<template>
  <div class="setbody">
    <div class="terms-content" ref="termsContent" @scroll="handleScroll">
      <span>ข้อกำหนดและเงื่อนไขการใช้บริการ SME Connect</span>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;นโยบายความเป็นส่วนตัวของลูกค้า
        นโยบายความเป็นส่วนตัวของลูกค้า Privacy Notice For SME BANK
        ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาคย่อมแห่งประเทศ ไทย (ธพว.)
        ตระหนักถึงความสำคัญในการคุ้มครองข้อมูลส่วนบุคคลของลูกค้า
        โดยข้อมูลส่วนบุคคลของท่านที่อยู่ในความรับผิดชอบของธนาคารจะได้รับความคุ้มครองอย่างเหมาะสม
        ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
        นโยบายความเป็นส่วนตัวฉบับนี้เป็นส่วนหนึ่งของข้อกำหนดและเงื่อนไขที่เกี่ยวข้อง
        ว่าด้วยความสัมพันธ์ระหว่างท่านและธนาคาร
      </p>
      <p>
        &nbsp;&nbsp;1.ข้อมูลที่ธนาคารทำการเก็บรวบรวม
        หรืออยู่ภายใต้นโยบายความเป็นส่วนตัว
        ธนาคารจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านต่อเมื่อธนาคารได้ข้อมูลจากท่าน
        โดยตรง หากท่านเลือกที่จะให้ความยินยอม
        หรือการเก็บรวบรวมจากแหล่งข้อมูลอื่นที่เปิดเผยต่อสาธารณชนทั่วไปอื่นๆ
        ธนาคารจะรักษาข้อมูลเหล่านั้นไว้เป็นความลับ
        ตามเกณฑ์มาตรฐานความปลอดภัยของธนาคาร ข้อมูลส่วนบุคคล เช่น ชื่อ นามสกุล
        วันเดือนปีเกิด เลขบัตรประจำตัวประชาชน ที่อยู่
        ไปรษณีย์อิเล็กทรอนิกส์เบอร์โทรศัพท์หรือเบอร์โทรสาร
        กิจกรรมทางธุรกิจหรือการดำเนินธุรกรรมใดๆ รวมถึงข้อมูลการสมัครงาน
        การลงทะเบียนสัมมนา หรือการสมัครเป็นสมาชิกของบริการต่างๆ เป็นต้น
      </p>
      <p>
        &nbsp;&nbsp;2.วัตถุประสงค์ในการเก็บรวบรวมใช้ หรือเปิดเผยข้อมูลส่วนบุดคล
        ข้อมูลส่วนบุคคลของท่านจะถูกนำไปใช้เพื่อวัตถุประสงค์ในการดำเนินงานของธนาคารอย่างถูกต้องตามกฎหมาย
        ด้วยความโปร่งใส และเป็นธรรม การเก็บรวบรวมข้อมูลของลูกค้าเท่าที่จำเป็น
        และเกี่ยวข้อง และใช้ตามวัตถุประสงค์ของท่านเท่านั้น
        ระยะเวลาการเก็บรักษาข้อมูลเหมาะสมกับวัตถุประสงค์ของการประมวลผลข้อมูลส่วนบุคคลหรือตามระยะเวลาที่กฎหมายกำหนด
        ธนาคารต้องขอความยินยอม (Consent) จากเจ้าของข้อมูลส่วนบุคคล
      </p>
      <p>
        • ต้องได้รับความยินยอมจากเจ้าของข้อมูลก่อนจึงจะเก็บรวบรวม ใช้
        เปิดเผยข้อมูลนั้นๆได้
      </p>
      <p>
        ลูกค้าองค์กรธุรกิจ:กรรมการ ผู้ถือหุ้น ผู้รับประโยชน์ที่แท้จริง พนักงาน
        ผู้คํ้าประกัน ผู้ให้หลักประกัน
        และผู้แทนโดยชอบด้วยกฎหมายของลูกค้าองค์กรธุรกิจเดิมและปัจจุบัน
        รวมถึงบุคคลธรรมดาอื่นที่มีอำนาจในการกระทำการแทนลูกค้าองค์กรธุรกิจ
        ทั้งนี้
        ธนาคารขอแนะนำให้ลูกค้าองค์กรธุรกิจของธนาคารดำเนินการเพื่อให้มั่นใจว่าบุคคลผู้มีอำนาจกระทำการแทนหรือบุคคลธรรมดาที่เกี่ยวข้องใดๆ
        รับทราบถึงประกาศนโยบายความเป็นส่วนตัวของธนาคาร
      </p>
      <p>
        (2) บุคคลที่ไม่ใช่ลูกค้าของธนาคาร
        บุคคลดังกล่าวรวมถึงบุคคลธรรมดาที่ไม่มีผลิตภัณฑ์หรือบริการกับธนาคาร
        แต่ธนาคารอาจมีความจำเป็นต้องเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เช่น ผู้ลงทุน
        บุคคลที่ได้ชำระหรือโอนเงินให้แก่หรือรับเงินจากลูกค้าของธนาคารหรือผู้ให้บริการโอนเงินในต่างประเทศ
        บุคคลที่ได้เข้าชมเว็บไซต์หรือแอปพลิเคชันของธนาคาร
        หรือเข้าใช้บริการที่สาขาหรือสำนักงานของธนาคาร
        ผู้ค้ำประกันหรือผู้ให้หลักประกัน ผู้รับผลประโยชน์ตามกรมธรรม์ประกัน
        ผู้รับผลประโยชน์ที่แท้จริง
        กรรมการหรือผู้แทนโดยชอบด้วยกฎหมายของนิติบุคคลที่ใช้บริการของธนาคาร
        ลูกหนี้หรือผู้เช่าของลูกค้าของธนาคาร ที่ปรึกษาด้านวิชาชีพ รวมถึงกรรมการ
        ผู้ลงทุนและผู้ถือหุ้นของธนาคาร และตัวแทนโดยชอบด้วยกฎหมายของบุคคลดังกล่าว
        และบุคคลใดๆ ที่เกี่ยวข้องกับการทำธุรกรรมกับธนาคารหรือลูกค้าของธนาคาร
      </p>
      <p>
        ทั้งนี้
        โปรดทราบว่าลิงก์ที่ปรากฏบนแพลตฟอร์มของธนาคารสามารถนำท่านเข้าสู่แพลตฟอร์มของบุคคลภายนอกได้
        ซึ่งหากท่านได้เข้าสู่แพลตฟอร์มของบุคคลภายนอกแล้วการประมวลผลข้อมูลส่วนบุคคลของท่านจะเป็นไปตามนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นทั้งสิ้น
        ด้วยเหตุนี้
        ธนาคารขอแนะนำให้ท่านอ่านและทำความเข้าใจนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นเมื่อท่านเข้าใช้แพลตฟอร์มนั้นๆ
      </p>
      <p>
        ธนาคารเก็บรวบรวมและนำข้อมูลส่วนบุคคลของท่านไปใช้หรือเปิดเผย อย่างไร
        ธนาคารจะเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเฉพาะกรณีที่จำเป็นหรือมีฐานทางกฎหมายในการเก็บรวบรวมใช้หรือเปิดเผยข้อมูลส่วนบุคคลเท่านั้น
        ซึ่งรวมถึงกรณีการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อการดำเนินการตามภาระหน้าที่ตามกฎหมาย
        การปฏิบัติตามสัญญาที่ท่านได้ทำไว้กับธนาคาร
        เพื่อประโยชน์โดยชอบด้วยกฎหมายของธนาคาร การดำเนินการตามความยินยอมของท่าน
        และ/หรือ ภายใต้ฐานทางกฎหมายอื่นๆ โดยวัตถุประสงค์ในการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลของธนาคาร ดังต่อไปนี้
      </p>
      <p>
        1.1 ภาระหน้าที่ตามกฎหมายของธนาคาร เนื่องจากธนาคารอยู่ภายใต้การกำกับดูแล
        และต้องดำเนินการตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง
        ธนาคารจึงมีความจำเป็นจะต้องเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ต่างๆ
        เพื่อให้เป็นไปตามกฎหมายและกฎระเบียบของหน่วยงานรัฐ และ/หรือ
        หน่วยงานที่มีหน้าที่กำกับดูแลธนาคาร
        ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะวัตถุประสงค์ดังต่อไปนี้
      </p>
      <p>
        ก) เพื่อปฏิบัติตามพรบ. คุ้มครองข้อมูลส่วนบุคคล และที่ได้แก้ไขเพิ่มเติม
      </p>
      <p>
        ข) เพื่อปฏิบัติตามกฎหมาย (เช่น กฎหมายธุรกิจสถาบันการเงิน
        กฎหมายหลักทรัพย์และตลาดหลักทรัพย์ กฎหมายป้องกันและปราบปรามการฟอกเงิน
        กฎหมายการป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง
        กฎหมายประกันชีวิตและประกันวินาศภัย และกฎหมายอื่นที่ธนาคารต้องปฏิบัติตาม
        ทั้งในประเทศไทยและต่างประเทศ) ซึ่งรวมถึงการดำเนินการตรวจสอบยืนยันตัวตน
        การตรวจสอบประวัติ การตรวจสอบเครดิต การทำความรู้จักลูกค้า (Know Your
        Customer) การตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับลูกค้า (Customer Due
        Diligence) และการตรวจสอบอื่น ๆ
        (รวมถึงการตรวจสอบจากฐานข้อมูลสาธารณะของหน่วยงานกำกับดูแล และ/หรือ
        ข้อมูลบุคคลที่ถูกกำหนด)
        และการดำเนินการอย่างต่อเนื่องเพื่อให้เป็นไปตามกฎหมายใด ๆ ที่เกี่ยวข้อง
        และ/หรือ
      </p>
      <p>
        &nbsp;&nbsp;2.วัตถุประสงค์ในการเก็บรวบรวมใช้ หรือเปิดเผยข้อมูลส่วนบุดคล
        ข้อมูลส่วนบุคคลของท่านจะถูกนำไปใช้เพื่อวัตถุประสงค์ในการดำเนินงานของธนาคารอย่างถูกต้องตามกฎหมาย
        ด้วยความโปร่งใส และเป็นธรรม การเก็บรวบรวมข้อมูลของลูกค้าเท่าที่จำเป็น
        และเกี่ยวข้อง และใช้ตามวัตถุประสงค์ของท่านเท่านั้น
        ระยะเวลาการเก็บรักษาข้อมูลเหมาะสมกับวัตถุประสงค์ของการประมวลผลข้อมูลส่วนบุคคลหรือตามระยะเวลาที่กฎหมายกำหนด
        ธนาคารต้องขอความยินยอม (Consent) จากเจ้าของข้อมูลส่วนบุคคล
      </p>
      <p>
        • ต้องได้รับความยินยอมจากเจ้าของข้อมูลก่อนจึงจะเก็บรวบรวม ใช้
        เปิดเผยข้อมูลนั้นๆได้
      </p>
      <p>
        ลูกค้าองค์กรธุรกิจ:กรรมการ ผู้ถือหุ้น ผู้รับประโยชน์ที่แท้จริง พนักงาน
        ผู้คํ้าประกัน ผู้ให้หลักประกัน
        และผู้แทนโดยชอบด้วยกฎหมายของลูกค้าองค์กรธุรกิจเดิมและปัจจุบัน
        รวมถึงบุคคลธรรมดาอื่นที่มีอำนาจในการกระทำการแทนลูกค้าองค์กรธุรกิจ
        ทั้งนี้
        ธนาคารขอแนะนำให้ลูกค้าองค์กรธุรกิจของธนาคารดำเนินการเพื่อให้มั่นใจว่าบุคคลผู้มีอำนาจกระทำการแทนหรือบุคคลธรรมดาที่เกี่ยวข้องใดๆ
        รับทราบถึงประกาศนโยบายความเป็นส่วนตัวของธนาคาร
      </p>
      <p>
        (2) บุคคลที่ไม่ใช่ลูกค้าของธนาคาร
        บุคคลดังกล่าวรวมถึงบุคคลธรรมดาที่ไม่มีผลิตภัณฑ์หรือบริการกับธนาคาร
        แต่ธนาคารอาจมีความจำเป็นต้องเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เช่น ผู้ลงทุน
        บุคคลที่ได้ชำระหรือโอนเงินให้แก่หรือรับเงินจากลูกค้าของธนาคารหรือผู้ให้บริการโอนเงินในต่างประเทศ
        บุคคลที่ได้เข้าชมเว็บไซต์หรือแอปพลิเคชันของธนาคาร
        หรือเข้าใช้บริการที่สาขาหรือสำนักงานของธนาคาร
        ผู้ค้ำประกันหรือผู้ให้หลักประกัน ผู้รับผลประโยชน์ตามกรมธรรม์ประกัน
        ผู้รับผลประโยชน์ที่แท้จริง
        กรรมการหรือผู้แทนโดยชอบด้วยกฎหมายของนิติบุคคลที่ใช้บริการของธนาคาร
        ลูกหนี้หรือผู้เช่าของลูกค้าของธนาคาร ที่ปรึกษาด้านวิชาชีพ รวมถึงกรรมการ
        ผู้ลงทุนและผู้ถือหุ้นของธนาคาร และตัวแทนโดยชอบด้วยกฎหมายของบุคคลดังกล่าว
        และบุคคลใดๆ ที่เกี่ยวข้องกับการทำธุรกรรมกับธนาคารหรือลูกค้าของธนาคาร
      </p>
      <p>
        ทั้งนี้
        โปรดทราบว่าลิงก์ที่ปรากฏบนแพลตฟอร์มของธนาคารสามารถนำท่านเข้าสู่แพลตฟอร์มของบุคคลภายนอกได้
        ซึ่งหากท่านได้เข้าสู่แพลตฟอร์มของบุคคลภายนอกแล้วการประมวลผลข้อมูลส่วนบุคคลของท่านจะเป็นไปตามนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นทั้งสิ้น
        ด้วยเหตุนี้
        ธนาคารขอแนะนำให้ท่านอ่านและทำความเข้าใจนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นเมื่อท่านเข้าใช้แพลตฟอร์มนั้นๆ
      </p>
      <p>
        ธนาคารเก็บรวบรวมและนำข้อมูลส่วนบุคคลของท่านไปใช้หรือเปิดเผย อย่างไร
        ธนาคารจะเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเฉพาะกรณีที่จำเป็นหรือมีฐานทางกฎหมายในการเก็บรวบรวมใช้หรือเปิดเผยข้อมูลส่วนบุคคลเท่านั้น
        ซึ่งรวมถึงกรณีการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อการดำเนินการตามภาระหน้าที่ตามกฎหมาย
        การปฏิบัติตามสัญญาที่ท่านได้ทำไว้กับธนาคาร
        เพื่อประโยชน์โดยชอบด้วยกฎหมายของธนาคาร การดำเนินการตามความยินยอมของท่าน
        และ/หรือ ภายใต้ฐานทางกฎหมายอื่นๆ โดยวัตถุประสงค์ในการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลของธนาคาร ดังต่อไปนี้
      </p>
      <p>
        1.1 ภาระหน้าที่ตามกฎหมายของธนาคาร เนื่องจากธนาคารอยู่ภายใต้การกำกับดูแล
        และต้องดำเนินการตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง
        ธนาคารจึงมีความจำเป็นจะต้องเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ต่างๆ
        เพื่อให้เป็นไปตามกฎหมายและกฎระเบียบของหน่วยงานรัฐ และ/หรือ
        หน่วยงานที่มีหน้าที่กำกับดูแลธนาคาร
        ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะวัตถุประสงค์ดังต่อไปนี้
      </p>
      <p>
        ก) เพื่อปฏิบัติตามพรบ. คุ้มครองข้อมูลส่วนบุคคล และที่ได้แก้ไขเพิ่มเติม
      </p>
      <p>
        ข) เพื่อปฏิบัติตามกฎหมาย (เช่น กฎหมายธุรกิจสถาบันการเงิน
        กฎหมายหลักทรัพย์และตลาดหลักทรัพย์ กฎหมายป้องกันและปราบปรามการฟอกเงิน
        กฎหมายการป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง
        กฎหมายประกันชีวิตและประกันวินาศภัย และกฎหมายอื่นที่ธนาคารต้องปฏิบัติตาม
        ทั้งในประเทศไทยและต่างประเทศ) ซึ่งรวมถึงการดำเนินการตรวจสอบยืนยันตัวตน
        การตรวจสอบประวัติ การตรวจสอบเครดิต การทำความรู้จักลูกค้า (Know Your
        Customer) การตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับลูกค้า (Customer Due
        Diligence) และการตรวจสอบอื่น ๆ
        (รวมถึงการตรวจสอบจากฐานข้อมูลสาธารณะของหน่วยงานกำกับดูแล และ/หรือ
        ข้อมูลบุคคลที่ถูกกำหนด)
        และการดำเนินการอย่างต่อเนื่องเพื่อให้เป็นไปตามกฎหมายใด ๆ ที่เกี่ยวข้อง
        และ/หรือ
      </p>
    </div>
    <div class="fixed-footer">
      <Buttons
        :primary="primary"
        :label="label"
        @click="goToPage"
        :disabled="!canAccept"
        :type="type"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();

const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo); // ดึงข้อมูลจาก getters

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ยอมรับ");

const isAgreed = ref(false);

const type = ref("button");

///--------- button end

/////------- start scroll bar

const termsContent = ref<HTMLElement | null>(null);
const canAccept = ref(false);

const handleScroll = () => {
  if (termsContent.value) {
    const { scrollTop, scrollHeight, clientHeight } = termsContent.value;
    // canAccept.value = scrollTop + clientHeight + 1 >= scrollHeight;
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      canAccept.value = true;
    } else {
      canAccept.value = false;
      isAgreed.value = false;
    }
  }
};

/////--------end scroll bar

const goToPage = () => {
  if (canAccept.value) {
    router.push({ name: "P02Enquiry" });
  }
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

span {
  text-align: center;
  font-size: 1.5rem;
}

p {
  text-align: left;
  font-size: 1rem;
}
.consent-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.terms-content {
  max-height: 82vh;
  overflow-y: auto;
  border-radius: 12px;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 20px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}


.bottom-marker {
  height: 1px;
  width: 100%;
  background-color: transparent;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accept-button {
  background-color: #007aff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
