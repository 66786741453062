import router from "@/routers/router";
import axios, { AxiosError } from "axios";

const axiosInstance = axios.create({
  // baseURL,
  baseURL: "https://connect-uat.smebank.co.th", // URL พื้นฐานของ API
  // baseURL: "https://" + process.env.APP_DN, // URL พื้นฐานของ API
  // baseURL: "http://127.0.0.1:8080", // URL พื้นฐานของ API

  // timeout: 1500, // เวลาหมดอายุ
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST",
    // ใส่ headers อื่น ๆ ตามต้องการ
  },
});

// Interceptor สำหรับจัดการกับ Response และตรวจสอบ HTTP status
axiosInstance.interceptors.response.use(
  (response) => {
    // ตรวจสอบสถานะการตอบกลับ (ถ้าต้องการจัดการกับ status code ที่ไม่ได้เป็น error)
    if (response.status === 200) {
    }
    return response;
  },
  (error: AxiosError) => {
    // ดึง status code จาก error response
    const status = error.response?.status;
    const statusText = error.response?.statusText;
    // const message = error.response?.message;
    // router.push({
    //   name: "error-enquiry",
    //   params: { code: status, detail: statusText },
    // });
    // กรณีพบ error ให้ทำการ redirect ไปยังหน้า error พร้อมกับรายละเอียดของ error
    if (status) {
      router.push({
        name: "error", // ชื่อ route ของหน้าแสดงข้อผิดพลาด
        params: { code: status, detail: statusText }, // ส่ง code และ detail ไปยังหน้า
      });
    } else {
      // ถ้าไม่มี status ให้ redirect ไปหน้า error ที่แสดงข้อความอื่น
      router.push({
        name: "error",
        params: { code: 500, detail: "Unknown error" },
      });
    }

    return Promise.reject(error); // ส่ง error กลับไปให้ส่วนอื่นจัดการถ้าจำเป็น
  }
);

export default axiosInstance;
