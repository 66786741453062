import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/check-icon.png'


const _hoisted_1 = { class: "content" }

import Buttons from "@/components/button/Buttons.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'P04SettingAutoSuccess',
  setup(__props) {

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ปิด");

const disable = ref(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  router.push("/");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "status-icon" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Check Icon",
        width: "100px",
        height: "100px"
      })
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "green" }, "ดำเนินการเรียบร้อยแล้ว", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "small" }, "การขอรับใบแจ้งหนี้และใบเสร็จ จะมีผลภายใน 1-2 วัน", -1)),
    _createVNode(Buttons, {
      primary: primary.value,
      label: label.value,
      onClick: handleClose,
      disabled: disable.value,
      type: type.value
    }, null, 8, ["primary", "label", "disabled", "type"])
  ]))
}
}

})