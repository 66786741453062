import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/c01.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "input-group" }

import Buttons from "@/components/button/Buttons.vue";
import PopUp from "@/components/pop_up/PopUp.vue";
import { onPostEnquiry } from "@/services/authService";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'P02Enquiry',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo);

const numberInput = ref<string>("");

/////// start pop up message
const isPopupVisible = ref<boolean>(false);
const popupMessage = ref<string>("");
const popupHeadText = ref<string>("");
/////   end pop up message

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ถัดไป");

const isButtonEnabled = ref<boolean>(false);

const type = ref("button");

///--------- button end

const handleInput = () => {
  if (numberInput.value.length >= 13) {
    isButtonEnabled.value = true;
  } else {
    isButtonEnabled.value = false;
  }
};

const handleSubmit = async () => {
  const data_req = {
    context_token: userInfo.value.context_token,
    cid: numberInput.value,
  };
  if (
    (data_req.context_token !== "" || data_req.context_token !== null) &&
    numberInput.value.length >= 13
  ) {
    await onCallPostEnquiry(data_req);
  }
};

const onCallPostEnquiry = async (value: any) => {
  let req = {
    ...value,
  };
  await onPostEnquiry(req).then(
    (res: any) => {
      if (res.limited !== true) {
        if (res.eligible === true) {
          store.dispatch("updateUserInfo", res);
          router.push({ name: "P05RequestOTP" });
        } else {
          // popupMessage.value = "ไม่พบข้อมูล";
          popupHeadText.value = res.reason.message;
          popupMessage.value = res.reason.detail;
          isPopupVisible.value = true;
        }
      } else if (res.limited === true) {
        router.push({ name: "error-enquiry" });
      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : "+err.value;
      popupMessage.value = "ข้อผิดพลาด "+err.value;
      router.push({name:"error"})
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", null, [
      _createElementVNode("b", null, "ระบุข้อมูลเพื่อยืนยันตัวตน")
    ], -1)),
    _cache[4] || (_cache[4] = _createElementVNode("p", { class: "pleft small" }, "ระบุเลขบัตรประจำตัวประชาชน หรือเลขนิติบุคคล", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "input-group-prepend" }, [
          _createElementVNode("span", { class: "input-group-text" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "icon"
            })
          ])
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "numberInput",
          maxlength: "13",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((numberInput).value = $event)),
          onInput: handleInput,
          placeholder: "กรุณาระบุหมายเลข",
          inputmode: "numeric"
        }, null, 544), [
          [_vModelText, numberInput.value]
        ])
      ])
    ]),
    _createVNode(Buttons, {
      primary: primary.value,
      label: label.value,
      onClick: handleSubmit,
      disabled: !isButtonEnabled.value,
      type: type.value
    }, null, 8, ["primary", "label", "disabled", "type"]),
    _createVNode(PopUp, {
      isVisible: isPopupVisible.value,
      message: popupMessage.value,
      headText: popupHeadText.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (isPopupVisible.value = false))
    }, null, 8, ["isVisible", "message", "headText"])
  ]))
}
}

})