import axiosInstance from "./axiosURL";

// export const onPostVerifyToken = async (obj: any) => {
//   try {
//     const res = await axiosInstance.post(`/token/verify`, obj);
//     return res.data;
//   } catch (error) {
//     console.error("There was an error onPostVerifyToken data!", error);
//   }
// };

export const onPostVerifyToken = async (obj: any) => {
  //
  return new Promise(async (resolve, reject) => {
    try {
      // let authorization = await axiosInstance();
      await axiosInstance
        .post(`/api/token/verify`, obj)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (err) {
      reject(err);
    } finally {
    }
  });
};

export const onPostConsent = async (obj: any) => {
  //
  return new Promise(async (resolve, reject) => {
    try {
      // let authorization = await axiosInstance();
      await axiosInstance
        .post(`/api/member/consent`, obj)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (err) {
      reject(err);
    } finally {
    }
  });
};

export const onPostEnquiry = async (obj: any) => {
  //
  return new Promise(async (resolve, reject) => {
    try {
      // let authorization = await axiosInstance();
      await axiosInstance
        .post(`/api/member/enquiry`, obj)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (err) {
      reject(err);
    } finally {
    }
  });
};
