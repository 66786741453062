import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-container" }

import { ref } from "vue";


const text = "...";

export default /*@__PURE__*/_defineComponent({
  __name: 'Loading',
  setup(__props) {

const textArray = ref(text.split(''));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "spinner" }, null, -1),
    _createElementVNode("p", { class: "moving-text" }, " กรุณารอสักครู่... ", -1)
  ])))
}
}

})