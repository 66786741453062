import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'P00RoutePage',
  setup(__props) {

// import Loading from "@/components/button/Loading.vue";
// import { onMounted, ref } from "vue";
// import { useRoute, useRouter } from "vue-router";
// import { useStore } from "vuex";
// import Cookies from "js-cookie";
// import { v4 as uuidv4 } from "uuid";


// const route = useRoute();

// const router = useRouter();

// const store = useStore();

// const isLoading = ref(true);

// let deviceUUID = Cookies.get("deviceUUID");

// if (!deviceUUID) {
//   // ถ้ายังไม่มี UUID ให้สร้างใหม่
//   deviceUUID = uuidv4();

//   // Cookies.set('deviceUUID', deviceUUID, { expires: 365 });  // เก็บ UUID ไว้ในคุกกี้ 1 ปี
// }


// const param_page = route.query.pages !== "2" ? "1" : route.query.pages || "1"; //กำหนด Pages ที่จะเรียกไป
// const param_value =
//   route.query.ctk ||
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1IjoiSXhtNDdtcVhlcnVoWXp0UW00b095dz09IiwiYSI6ImNnT3N6ZlFtUThyVjJMRFQyV2s2VlBTSG9QK0txNzNZR1FqTm1zbFVOSjZybzUzZk9RMzk2cUJFTDBNWDA1RExFTkVpQ0NSbnNxbVVjL1N6S1I4Qnd3dVFaNjVscnJmejRpL0ZPVUVaNjdRPSIsImV4cCI6MTcyODI5NTI1MiwiaWF0IjoxNzI4MjkxNjQ3fQ.88RLTzoM6j6BY5koEKhWUHvsaeoWioaSpKafb4XBEc4";

// onMounted(() => {

//   // เรียกใช้ฟังก์ชันเพื่อดึง IP Address
//   setTimeout(() => {
//     localStorage.removeItem("userInfo"); // เคลียร์ข้อมูลก่อนเริ่มใช้งานแอป
//     store.dispatch("clearUserInfo"); // เรียก action ของ Vuex เพื่อเคลียร์ข้อมูลใน Store
//     //   console.log("testtt route");
//     store.dispatch("updateUserInfo", { context_token: param_value }); // บันทึกข้อมูลใหม่ลง Store และ localStorage
//     goToPages();
//   }, 500);

// });

// function goToPages() {
//   if (param_page === "1" && param_value !== "") {
//     router.push({
//       name: "P01Consent",
//     });
//     isLoading.value = false;
//   } else if (param_page === "2" && param_value !== "") {
//     router.push({ name: "P06Settings" });
//     isLoading.value = false;
//   } else {
//     router.push({ name: "error" });
//     isLoading.value = false;
//   }
// }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "Testtttt", -1)
  ])))
}
}

})