<template>
  <div v-if="showCancelPopup" class="popup-overlay" @click="close">
    <Loading v-if="isLoading" />
    <div class="form-group">
      <div class="popup-content" @click.stop>
        <div class="popup-content">
          <p>หากท่านต้องการ<u class="text-danger">ยกเลิกบริการ</u></p>
          <p style="line-height: 1.2em">SME Connect กรุณายืนยันคำสั่ง</p>
        </div>
        <div class="otp-content">
          <div>
            <p style="font-size: 50; background-color: #ccc; color: black">
              {{ numberCancle }}
            </p>
          </div>
          <span style="cursor: pointer" @click="requestNewOtp">
            <p style="line-height: 1.2em">
              <u style="font-size: 20px; color: blue">ขอรหัสใหม่</u>
            </p>
          </span>
          <p style="color: black">พิมพ์ตัวเลขตามภาพ :</p>
          <div class="input-group input-group-cancel">
            <input
              v-model="userInput"
              type="text"
              class="form-control form-control-cancel"
              placeholder="กรอกตัวเลข"
              maxlength="6"
              @input="onCheckLengthNumber"
            />
          </div>
          <div v-if="chkNumberGener && count_gener_input !== 0">
            <p class="text-danger" style="font-size: 20px">
              กรอกรหัสไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง {{ count_gener_input }} /
              3
            </p>
          </div>
          <Buttons
            :primary="primary"
            :label="label"
            @click="onClickComfirm"
            :disabled="
              !onCheckLengthNumber() || count_gener_input === 3 || isDisableBtn
            "
            :type="type"
          />
          <!-- <button
            class="btn btn-primary btn-block"
            @click="onClickComfirm"
            :disabled="!onCheckLengthNumber()"
          >
            ยืนยัน
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import { onPostRemoveSettings } from "@/services/member";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { ref, defineEmits, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const count_gener_input = ref(0);

const isLoading = ref(true);

const isDisableBtn = ref(false);

///--------- button start

const primary = ref("btn btn-primary btn-block");

const label = ref("ยืนยัน");

const type = ref("button");

///--------- button end

const props = defineProps<{
  showCancelPopup: boolean;
}>();

const emit = defineEmits<{
  (event: "update:showCancelPopup", showCancelPopup: boolean): void;
}>();

const router = useRouter();

const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo);

const numberCancle = ref(generateOtp());
const userInput = ref("");
const chkNumberGener = ref<boolean>(false);

function generateOtp() {
  return Math.floor(100000 + Math.random() * 900000).toString();
}

function requestNewOtp() {
  numberCancle.value = generateOtp();
  count_gener_input.value = 0;
  userInput.value = "";
}

const onClickComfirm = () => {
  if (userInput.value === numberCancle.value) {
    isDisableBtn.value = true;
    onPostRemoveSettingsCallService();
  } else {
    count_gener_input.value += 1;
    chkNumberGener.value = true;
  }
};

const onPostRemoveSettingsCallService = () => {
  // remove data user setting
  let req = {
    context_token: userInfo.value.context_token,
  };
  isLoading.value = true;
  onPostRemoveSettings(req).then(
    (res: any) => {
      isLoading.value = false;
      isDisableBtn.value = false;
      if (res.is_done === true) {
        const newValue = false;
        emit("update:showCancelPopup", newValue);
        router.push({ name: "P11SeccessCancelReceipt" });
      } else {
      }
    },
    function (err: any) {
      isLoading.value = false;
      isDisableBtn.value = false;
      console.log("Message error", err);
      router.push({ name: "error" });
    }
  );
};

const onCheckLengthNumber = () => {
  return userInput.value.length === 6;
};

const close = () => {
  const newValue = false;
  emit("update:showCancelPopup", newValue);
};
</script>

<style scoped>
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-control-cancel {
  max-width: 80%;
}

.input-group-cancel {
  justify-content: space-evenly;
}
</style>
