<template>
  <div class="container">
    <div class="content">
      <div class="status-icon">
        <img
          src="../assets/img/check-icon.png"
          alt="Check Icon"
          width="100px"
          height="100px"
        />
      </div>
      <h2 class="green">ดำเนินการเรียบร้อยแล้ว</h2>
      <p class="small">
        บริการใบแจ้งหนี้และใบเสร็จ ผ่าน SME Connect อัตโนมัติจะมีผลภายใน 1-2 วัน
      </p>

      <p class="small">ขอบพระคุณที่ให้การสนับสนุน ลดการใช้กระดาษ</p>
      <p class="small">เพื่อรวมเป็นส่วนหนึ่งของการลดภาวะโลกร้อน</p>

      <div class="fixed-footer">
        <div class="txt-footer">
          <p>หากต้องการยกเลิกบริการดังกล่าว</p>
          <a href="tel:1357">กรุณาติดต่อ SME D Bank Call Center โทร.1357</a>
        </div>
        <div>
          <Buttons
            :primary="primary"
            :label="label"
            @click="handleClose"
            :disabled="isAgreed"
            :type="type"
          />
        </div>
      </div>
      <!-- <button class="btn btn-primary btn-block btnfoot" @click="handleClose">
        ปิด
      </button> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block btnfoot");

const label = ref("ปิด");

const isAgreed = ref<boolean>(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  router.push("/");
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

a {
  color: blue;
  font-weight: bolder;
  text-decoration: underline;
}

.txt-footer{
  align-items: center;
  
}


/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
