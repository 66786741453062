import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/img/phone-icon.png'


const _hoisted_1 = {
  key: 0,
  class: "hide-txt"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "grey" }
const _hoisted_5 = { class: "otp-input" }
const _hoisted_6 = ["onUpdate:modelValue", "onInput", "onKeydown", "onFocus", "disabled"]
const _hoisted_7 = {
  key: 0,
  class: "small"
}
const _hoisted_8 = {
  key: 1,
  class: "small"
}
const _hoisted_9 = { class: "text-danger" }
const _hoisted_10 = {
  key: 2,
  class: "small"
}
const _hoisted_11 = { class: "text-danger" }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 1 }

import Buttons from "@/components/button/Buttons.vue";
import Loading from "@/components/button/Loading.vue";
import OtpPopup from "@/components/pop_up/OtpPopup.vue";
import PopUp from "@/components/pop_up/PopUp.vue";
import { onPostLock, onPostRequest, onPostVerify } from "@/services/otpService";
// import liff from "@line/liff";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const numberOfFields = 6;

export default /*@__PURE__*/_defineComponent({
  __name: 'P05RequestOTP',
  setup(__props) {

const router = useRouter();
const store = useStore();
const userInfo = computed(() => store.getters.getUserInfo);

//////// start pop up
const isPopupVisible = ref<boolean>(false);

const popupMessage = ref<string>("");

const popupHeadText = ref<string>("");

///// end pop up

///---------- set count otp start

const count_req_otp = ref(0); //นับจำนวนหมายเลข OTP ใหม่ครบ 5 ครั้ง

const count_input_otp = ref(0); //นับจำนวนการกรอกเลข OTP หลักจากกดยืนยัน

const message_input_otp = ref<string>(""); // message การโชว์นับจำนวนการกรอกเลข OTP ผิด

const total_req_otp = ref(0); // นับจำนวนรอบ count_req_otp 5 ครั้งถือเป็นการนับ 1 รอบ

const isLoading = ref(true);

///---------- set count otp end

///--------- button start

const primary_req = ref("btn-primary btn");

const label_req = ref("ส่งอีกครั้ง");

const type_req = ref("button");

const primary_next = ref("btn-primary btn-block");

const label_next = ref("ถัดไป");

const chkValidateOtp = ref<boolean>(true);

const type_next = ref("button");

///--------- button end

///-------- start run time otp and generate otp number

const otp = ref(generateOtp());

const data_res_otp = ref({
  context_token: "",
  mobile_no: "",
  otp_ref: "",
  limited: false,
});

const timeLimit = ref(180); // 3 minutes in seconds
// const timeLimit = ref(3); // 3 minutes in seconds

const minutes = computed(() => Math.floor(timeLimit.value / 60));
const seconds = computed(() =>
  (timeLimit.value % 60).toString().padStart(2, "0")
);

const showOtpPopup = ref(false);

const interval = ref(0);

const isButtonDisabled = computed(
  /// ไว้ disable ปุ่มขอหมายเลข otp ใหม่
  () => timeLimit.value <= 0 && total_req_otp.value <= 2
); // เงื่อนไขการ disable button sent otp

// onMounted(() => {
//   startCountdown();
// });

onMounted(() => {
  setTimeout(() => {
      onPostRequestCall();
  }, 2000);
});

function generateOtp() {
  return Math.floor(100000 + Math.random() * 900000).toString();
} //  สร้างเอง ตอนนี้เปลี่ยนเป็น SMS 13092024

const startCountdown = () => {
  /// ไว้นับเวลา
  interval.value = window.setInterval(() => {
    if (timeLimit.value > 0) {
      timeLimit.value--;
    } else {
      clearInterval(interval.value);
    }
  }, 1000);
};

function requestNewOtp() {
  //request generate new otp number
  if (total_req_otp.value < 2 && count_req_otp.value < 5) {
    //check count sent otp
    // otp.value = generateOtp();
    // timeLimit.value = 3; // Reset the timer
    timeLimit.value = 180; // Reset the timer
    count_input_otp.value = 0;
    showOtpPopup.value = false;
    count_req_otp.value += 1;
    chkValidateOtp.value = false;
    onPostRequestCall();
    // startCountdown();
  } else {
    if (total_req_otp.value === 2) {
      popupHeadText.value = " ขอรหัส OTP เกินจำนวนครั้งที่กำหนด";
      popupMessage.value = `ท่านถูกล็อคการขอรหัส OTP 24 ชม. หากต้องต้องการดำเนินการต่อทันที กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
      isPopupVisible.value = true;
    } else {
      // timeLimit.value = 3; // Reset the timer
      timeLimit.value = 180; // Reset the timer
      showOtpPopup.value = false;
      startCountdown();
      count_input_otp.value = 0;
      count_req_otp.value = 0;
      total_req_otp.value += 1;
    }
  }
}

////-------- end run time otp

///////-------------------intut otp start

const otpValues = ref<string[]>(new Array(numberOfFields).fill(""));
const digits = ref<string[]>(Array(numberOfFields).fill(""));

// สร้าง ref สำหรับ input fields
const otpFields = ref<(HTMLInputElement | null)[]>(
  new Array(numberOfFields).fill(null)
);

// ฟังก์ชันจัดการการป้อนข้อมูล
const handleInput = async (index: number) => {
  const currentValue = otpValues.value[index];

  if (/^\d$/.test(currentValue)) {
    // กรณีที่ช่องไม่ใช่ช่องสุดท้ายและมีค่า OTP ที่ถูกต้อง
    if (index < numberOfFields - 1 && otpValues.value[index] !== "") {
      otpFields.value[index + 1]?.focus();
    }

    if (index === 5) {
      chkValidateOtp.value = false;
    }
  } else {
    // ทำให้ช่องป้อนข้อมูลว่าง
    otpValues.value[index] = "";

    if (index === 5) {
      chkValidateOtp.value = true;
    }
  }
};

// ฟังก์ชันจัดการการกดปุ่ม
const handleKeydown = async (index: number, event: KeyboardEvent) => {
  if (event.key === "Backspace" && otpValues.value[index] === "") {
    if (index > 0) {
      otpFields.value[index - 1]?.focus();
    }
  } else if (event.key === "ArrowLeft" && index > 0) {
    otpFields.value[index - 1]?.focus();
  } else if (event.key === "ArrowRight" && index < numberOfFields - 1) {
    otpFields.value[index + 1]?.focus();
  }
};

// ฟังก์ชันจัดการการโฟกัส
const handleFocus = (index: number) => {
  const field = otpFields.value[index];
  if (field) {
    field.setSelectionRange(0, field.value.length);
  }
};

/////////////--------------------end input

////// call api service start

const onPostRequestCall = async () => {
  /// ขอ otp
  let req = {
    context_token: userInfo.value.context_token,
  };
  await onPostRequest(req).then(
    (res: any) => {
      if (res) {
          data_res_otp.value = {
            ...res,
          };
          // count_input_otp.value = 0
          startCountdown(); 
      }else{
        router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code:  '999',
        message: 'check error api'
      }
    });

      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : " + err.value;
      popupMessage.value = "ข้อผิดพลาด " + err.value;
      // router.push({ name: "error" });
    }
  );
};

const showPopup = () => {
  if (total_req_otp.value < 2) {
    digits.value = otpValues.value;
    if (otpValues.value.length === 6) {
      // const otpEntered = digits.value.join("");
      // if (otpEntered === otp.value) {
      //   onPostVerifyTokenCallService();
      // } else
      if (count_input_otp.value < 3) {
        count_input_otp.value += 1;
        message_input_otp.value = `OTP ไม่ถูกต้อง กรุณาตรวจสอบและระบุใหม่อีกครั้ง (${count_input_otp.value} / 3)`;
      } else if (count_input_otp.value === 3) {
        message_input_otp.value = `ท่านระบุ OTP ไม่ถูกต้องครบจำนวนครั้งที่กำหนด กรุณากด "กดอีกครั้ง" เพื่อรับรหัส OTP ใหม่`;
        chkValidateOtp.value = true;
      }
    }
  } else {
    onPostLockCallService();
    // router.push({ name: "P01Component" });
  }
};

const onPostVerifyTokenCallService = async () => {
  /// verify otp
  // verify otp succes next page
  let req = {
    ...data_res_otp.value,
    otp: otpValues.value.join(""),
  };
  await onPostVerify(req).then(
    (res: any) => {
      // console.log(res);
      if (res.is_passed === true) {
        router.push({ name: "P07VerifyOTPSuccess" });
      } else {
        showPopup();
      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : " + err.value;
      popupMessage.value = "ข้อผิดพลาด " + err.value;
      // router.push({ name: "error" });
    }
  );
};

const onPostLockCallService = async () => {
  // check user lock sent request otp
  let req = {
    ...data_res_otp.value,
  };
  await onPostLock(req).then(
    (res: any) => {
      if (res.is_locked === true) {
        popupHeadText.value = " ขอรหัส OTP เกินจำนวนครั้งที่กำหนด";
        popupMessage.value = `ท่านถูกล็อคการขอรหัส OTP 24 ชม. หากต้องต้องการดำเนินการต่อทันที กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
        isPopupVisible.value = true;
        // total_req_otp.value = 0;
        // count_input_otp.value = 0;
        // count_req_otp.value = 0;
        router.push({ name: "P01Consent" });
        handleClose();
        chkValidateOtp.value = true;
      } else {
        ///เงื่อนไขการถูก block
        // popupHeadText.value = " ขอรหัส OTP เกินจำนวนครั้งที่กำหนด";
        // popupMessage.value = `กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
        // isPopupVisible.value = true;
        popupHeadText.value = "เกิดข้อผิดพลาด";
        popupMessage.value = `กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
        isPopupVisible.value = true;
      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : " + err.value;
      popupMessage.value = "ข้อผิดพลาด " + err.value;
      // router.push({ name: "error" });
    }
  );
};

// end call api service

const handleClose = () => {
  // router.push({ name: "P06Settings" });
  // ตรวจสอบว่ามีการโหลด LIFF สำเร็จก่อนที่จะปิดหน้าต่าง
  // if (liff.isInClient()) {
  // liff.closeWindow(); // ปิดแอปพลิเคชัน เรียกใบ้ได้เฉพาะบน line เท่านั้น
  window.postMessage("closeWebView", "*");
  window.close();
  // } else {
  // router.push({ name: "P01Consent" });
  // console.error("Not running inside LINE app.");
  // }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (data_res_otp.value.limited === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (data_res_otp.value.otp_ref !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _cache[4] || (_cache[4] = _createElementVNode("h2", null, "รหัส OTP จะส่งไปยัง", -1)),
                  _createElementVNode("p", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("img", {
                      src: _imports_0,
                      width: "20px"
                    }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(data_res_otp.value.mobile_no), 1)
                  ]),
                  _createElementVNode("p", _hoisted_4, "Ref. " + _toDisplayString(data_res_otp.value.otp_ref), 1),
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(otpValues.value, (value, index) => {
                      return _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: index,
                        class: "otp-field",
                        maxlength: 1,
                        "onUpdate:modelValue": ($event: any) => ((otpValues.value[index]) = $event),
                        onInput: ($event: any) => (handleInput(index)),
                        onKeydown: ($event: any) => (handleKeydown(index, $event)),
                        onFocus: ($event: any) => (handleFocus(index)),
                        ref_for: true,
                        ref: (el) => (otpFields.value[index] = el),
                        inputmode: "numeric",
                        disabled: data_res_otp.value.otp_ref === ''
                      }, null, 40, _hoisted_6)), [
                        [_vModelText, otpValues.value[index]]
                      ])
                    }), 128))
                  ]),
                  (count_input_otp.value === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[3] || (_cache[3] = [
                        _createElementVNode("p", { class: "text-danger" }, null, -1)
                      ])))
                    : (count_input_otp.value > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("p", _hoisted_9, _toDisplayString(message_input_otp.value), 1)
                        ]))
                      : (count_input_otp.value === 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("p", _hoisted_11, _toDisplayString(message_input_otp.value), 1)
                          ]))
                        : _createCommentVNode("", true),
                  _createElementVNode("p", null, "กรุณาใส่รหัส OTP ภายใน " + _toDisplayString(minutes.value) + ":" + _toDisplayString(seconds.value) + " นาที", 1),
                  _createVNode(Buttons, {
                    primary: primary_next.value,
                    label: label_next.value,
                    onClick: onPostVerifyTokenCallService,
                    disabled: chkValidateOtp.value,
                    type: type_next.value
                  }, null, 8, ["primary", "label", "disabled", "type"]),
                  (data_res_otp.value.otp_ref !== '' && isButtonDisabled.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("span", null, [
                          _createElementVNode("p", {
                            class: "p-text-req",
                            onClick: requestNewOtp
                          }, " ส่งอีกครั้ง ")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : (data_res_otp.value.otp_ref === '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _cache[5] || (_cache[5] = _createElementVNode("h2", null, "กำลังส่งรหัส OTP ไปยังเบอร์ของคุณ", -1)),
                  (isLoading.value)
                    ? (_openBlock(), _createBlock(Loading, {
                        key: 0,
                        class: "load-style"
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
        ]))
      : (data_res_otp.value.limited === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[6] || (_cache[6] = [
            _createElementVNode("h2", null, "ไม่สามารถส่งรหัส OTP ได้", -1),
            _createElementVNode("p", null, "เนื่องจากคุณขอรหัส OTP เกินกว่าจำนวนครั้งที่กำหนด", -1)
          ])))
        : _createCommentVNode("", true),
    _cache[7] || (_cache[7] = _createElementVNode("p", { class: "info-text" }, [
      _createTextVNode(" ⓘ หากต้องการเปลี่ยนเบอร์โทรศัพท์ กรุณาติดต่อเจ้าหน้าที่สาขา SME D Bank ทั่วประเทศ หรือ "),
      _createElementVNode("a", { href: "tel:1357" }, " SME D Bank Call Center โทร. 1357 ")
    ], -1)),
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "fixed-footer" }, [
      _createElementVNode("span", null, [
        _createTextVNode(" อ่านวิธีที่เราเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล และเข้าใจสิทธิของคุณที่ "),
        _createElementVNode("a", { href: "https://www.smebank.co.th/privacy-policy/" }, "ประกาศนโยบายความเป็นส่วนตัว")
      ])
    ], -1)),
    _createVNode(PopUp, {
      isVisible: isPopupVisible.value,
      message: popupMessage.value,
      headText: popupHeadText.value,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (isPopupVisible.value = false))
    }, null, 8, ["isVisible", "message", "headText"]),
    _createVNode(OtpPopup, {
      otp: otp.value,
      showOtpPopup: showOtpPopup.value,
      "onUpdate:showOtpPopup": _cache[1] || (_cache[1] = ($event: any) => (showOtpPopup.value = $event))
    }, null, 8, ["otp", "showOtpPopup"])
  ]))
}
}

})