<template lang="">
  <div>
    <div class="content">
      <div class="status-icon">
        <img
          src="../assets/img/check-icon.png"
          alt="Check Icon"
          width="100px"
          height="100px"
        />
      </div>
      <h2 class="green">ดำเนินการเรียบร้อยแล้ว</h2>
      <p class="small">บริการขอใบแจ้งหนี/ใบเสร็จ</p>
      <p class="small">มีผลทันทีหลังจากสมัครใช้งาน SME D Bank สำเร็จ</p>
      <!-- <button class="btn btn-primary btn-block btnfoot" @click="handleClose">
        ถัดไป
      </button> -->
    </div>
    <div class="fixed-footer">
      <!-- <Buttons
        :primary="primary"
        :label="label"
        @click="handleClose"
        :disabled="disable"
        :type="type"
      /> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Buttons from "@/components/button/Buttons.vue";
// import liff from "@line/liff";
// import { initializeLiff } from "../services/liff";

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ปิด");

const disable = ref(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  // router.push({ name: "P06Settings" });
  // ตรวจสอบว่ามีการโหลด LIFF สำเร็จก่อนที่จะปิดหน้าต่าง
  // if (liff.isInClient()) {
  //   // console.log("🚀 ~ handleClose ~ liff.isInClient():", liff.isInClient());
  //   window.close();
  //   liff.closeWindow(); // ปิดแอปพลิเคชัน เรียกใบ้ได้เฉพาะบน line เท่านั้น
  // } else {
  //   liff.closeWindow(); // ปิดแอปพลิเคชัน เรียกใบ้ได้เฉพาะบน line เท่านั้น
    window.postMessage("closeWebView", "*");
    window.close();
    // router.push({ name: "P01Consent" });

    // console.error("Not running inside LINE app.");
  // }
};
// initializeLiff();
</script>

<style scoped>
p {
  align-items: center;
}
.small {
  margin-top: 3vh;
  font-size: 26px;
  line-height: 1.2em;
  align-items: center;
}
</style>
