<template>
  <div class="setbody">
    <div class="logo">
      <img
        src="../assets/img/logo.png"
        width="150px"
        alt="SME Development Bank"
      />
    </div>
    <div class="content">
      <article class="column1">
        <p class="txthead">SME Connect</p>
        <div class="text-detail">
          <p>สมัครขอใบแจ้งหนี้และใบเสร็จผ่าน Line</p>
          <p>สำหรับลูกค้านิติบุคคล</p>
        </div>
      </article>
      <Buttons
        :primary="primary"
        :label="label"
        @click="goToPage"
        :disabled="disable"
        :type="type"
      />
    </div>
    <div class="fixed-footer">
      อ่านวิธีที่เราเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล
      และเข้าใจสิทธิของคุณที่
      <a href="https://www.smebank.co.th/privacy-policy/"
        >ประกาศนโยบายความเป็นส่วนตัว</a
      >
    </div>
    <PopUp
      :isVisible="isPopupVisible"
      :message="popupMessage"
      :headText="popupHeadText"
      @close="isPopupVisible = false"
    />
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import PopUp from "@/components/pop_up/PopUp.vue";
import { onPostConsent, onPostVerifyToken } from "@/services/authService";
// import liff from "@line/liff";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();

const route = useRoute();

const getCtk = computed(() => route.query.ctk || "");

const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo); ///ใช้ชั่วคราว

/////// start pop up message
const isPopupVisible = ref<boolean>(false);
const popupMessage = ref<string>("");
const popupHeadText = ref<string>("");
/////   end pop up message

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ระบุข้อมูลเพื่อยืนยันตัวตน");

const disable = ref(false);

const type = ref("button");

///--------- button end

// onMounted(() => {
//   localStorage.removeItem("userInfo"); // เคลียร์ข้อมูลก่อนเริ่มใช้งานแอป
//   store.dispatch("clearUserInfo"); // เรียก action ของ Vuex เพื่อเคลียร์ข้อมูลใน Store
// });

const goToPage = async () => {
  const data_req = {
    context_token:
      getCtk.value !== "" ? getCtk.value : userInfo.value.context_token,
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoidGVzdCIsImV4cCI6MTcyNjQ4MTE4MywiaWF0IjoxNzI2NDc3NTgzfQ.y4nb_LwwgmVcjaSzu9NIUKFn0MDB2200gE1qNnPUHw4",
    // context_token:
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMTIzMTIzMTIzMTIzMTIiLCJleHAiOjE3NTc3MzM0OTcsImlhdCI6MTcyNjE5NzQ5N30.kw4AShHYqhqa3vF214XUfq1d2T6SRJfV-JS5tfe1tws",
  };
  await onPostVerifyToken(data_req).then(
    (res: any) => {
      // setLoading(false);
      if (!res.error_message) {
        if (res.is_done === true) {
          onPostConsentCall(res);
        } else {
          popupHeadText.value = "แจ้งเตือน";
          popupMessage.value = "ไม่พบข้อมูล";
        }
      } else {
        popupHeadText.value = "แจ้งเตือน";
        popupMessage.value = "ไม่พบข้อมูล";
        isPopupVisible.value = true;
      }
    },
    function (err: any) {
      popupHeadText.value = "เกิดข้อผิดพลาด : "+err.value;
      popupMessage.value = "ข้อผิดพลาด "+err.value;
      router.push({name:"error"})
    }
  );
};

const onPostConsentCall = async (obj: any) => {
  let req = {
    ...obj,
  };
  await onPostConsent(req).then(
    (res: any) => {
      if (!res.error_message) {
        if (res.is_done === true) {
          store.dispatch("updateUserInfo", res); // บันทึกข้อมูลใหม่ลง Store และ localStorage
          router.push({
            name: "P03ConsentVerify",
          });
        } else {
          popupHeadText.value = "แจ้งเตือน";
          popupMessage.value = "ไม่พบข้อมูล";
        }
      } else {
        popupHeadText.value = "แจ้งเตือน";
        popupMessage.value = "ไม่พบข้อมูล";
        isPopupVisible.value = true;
      }
    },
    function (err: any) {
      popupHeadText.value = "เกิดข้อผิดพลาด : "+err.value;
      popupMessage.value = "ข้อผิดพลาด "+err.value;
      router.push({name:"error"})
    }
  );
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

.txthead {
  color: #161caf;
  font-size: 63px;
  font-weight: bolder;
}

a {
  color: blue;
  font-weight: bolder;
  text-decoration: underline;
}

p {
  align-items: center;
}

.fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  background-color: white;
  padding: 20px;
  font-size: 18px;
}

.column1 {
  line-height: 2.5em;
}

.text-detail {
  color: #000;
  font-size: 30px;
  font-weight: bold;
}

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
