<template>
  <div>
    <div class="hide-txt" v-if="data_res_otp.limited === false">
      <div v-if="data_res_otp.otp_ref !== ''">
        <div class="content">
          <h2>รหัส OTP จะส่งไปยัง</h2>
          <p>
            <img src="../assets/img/phone-icon.png" width="20px" />
            {{ data_res_otp.mobile_no }}
          </p>

          <p class="grey">Ref. {{ data_res_otp.otp_ref }}</p>
          <div class="otp-input">
            <input
              v-for="(value, index) in otpValues"
              :key="index"
              class="otp-field"
              :maxlength="1"
              v-model="otpValues[index]"
              @input="handleInput(index)"
              @keydown="handleKeydown(index, $event)"
              @focus="handleFocus(index)"
              :ref="(el) => (otpFields[index] = el)"
              inputmode="numeric"
              :disabled="data_res_otp.otp_ref === ''"
            />
          </div>
          <div v-if="count_input_otp === 0" class="small">
            <p class="text-danger"></p>
          </div>
          <div v-else-if="count_input_otp > 0" class="small">
            <p class="text-danger">{{ message_input_otp }}</p>
          </div>
          <div v-else-if="count_input_otp === 3" class="small">
            <p class="text-danger">{{ message_input_otp }}</p>
          </div>

          <p>กรุณาใส่รหัส OTP ภายใน {{ minutes }}:{{ seconds }} นาที</p>
          <Buttons
            :primary="primary_next"
            :label="label_next"
            @click="onPostVerifyTokenCallService"
            :disabled="chkValidateOtp"
            :type="type_next"
          />
          <div v-if="data_res_otp.otp_ref !== '' && isButtonDisabled">
            <span
              ><p class="p-text-req" v-on:click="requestNewOtp">
                ส่งอีกครั้ง
              </p></span
            >
          </div>

          <!-- <Buttons
        :primary="primary_req"
        :label="label_req"
        @click="requestNewOtp"
        :disabled="!isButtonDisabled"
        :type="type_req"
      /> -->
        </div>
      </div>
      <div v-else-if="data_res_otp.otp_ref === ''">
        <h2>กำลังส่งรหัส OTP ไปยังเบอร์ของคุณ</h2>
        <!-- <p>กรุณารอสักครู่...</p> -->

        <Loading class="load-style" v-if="isLoading" />
      </div>
    </div>
    <div v-else-if="data_res_otp.limited === true">
      <h2>ไม่สามารถส่งรหัส OTP ได้</h2>
      <p>เนื่องจากคุณขอรหัส OTP เกินกว่าจำนวนครั้งที่กำหนด</p>
    </div>

    <p class="info-text">
      ⓘ หากต้องการเปลี่ยนเบอร์โทรศัพท์ กรุณาติดต่อเจ้าหน้าที่สาขา SME D Bank
      ทั่วประเทศ หรือ
      <a href="tel:1357"> SME D Bank Call Center โทร. 1357 </a>
    </p>

    <div class="fixed-footer">
      <!-- <br /> -->
      <span>
        อ่านวิธีที่เราเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล
        และเข้าใจสิทธิของคุณที่
        <a href="https://www.smebank.co.th/privacy-policy/"
          >ประกาศนโยบายความเป็นส่วนตัว</a
        >
      </span>
    </div>

    <PopUp
      :isVisible="isPopupVisible"
      :message="popupMessage"
      :headText="popupHeadText"
      @close="isPopupVisible = false"
    />

    <OtpPopup
      :otp="otp"
      :showOtpPopup="showOtpPopup"
      @update:showOtpPopup="showOtpPopup = $event"
    />
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import Loading from "@/components/button/Loading.vue";
import OtpPopup from "@/components/pop_up/OtpPopup.vue";
import PopUp from "@/components/pop_up/PopUp.vue";
import { onPostLock, onPostRequest, onPostVerify } from "@/services/otpService";
// import liff from "@line/liff";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
const userInfo = computed(() => store.getters.getUserInfo);

//////// start pop up
const isPopupVisible = ref<boolean>(false);

const popupMessage = ref<string>("");

const popupHeadText = ref<string>("");

///// end pop up

///---------- set count otp start

const count_req_otp = ref(0); //นับจำนวนหมายเลข OTP ใหม่ครบ 5 ครั้ง

const count_input_otp = ref(0); //นับจำนวนการกรอกเลข OTP หลักจากกดยืนยัน

const message_input_otp = ref<string>(""); // message การโชว์นับจำนวนการกรอกเลข OTP ผิด

const total_req_otp = ref(0); // นับจำนวนรอบ count_req_otp 5 ครั้งถือเป็นการนับ 1 รอบ

const isLoading = ref(true);

///---------- set count otp end

///--------- button start

const primary_req = ref("btn-primary btn");

const label_req = ref("ส่งอีกครั้ง");

const type_req = ref("button");

const primary_next = ref("btn-primary btn-block");

const label_next = ref("ถัดไป");

const chkValidateOtp = ref<boolean>(true);

const type_next = ref("button");

///--------- button end

///-------- start run time otp and generate otp number

const otp = ref(generateOtp());

const data_res_otp = ref({
  context_token: "",
  mobile_no: "",
  otp_ref: "",
  limited: false,
});

const timeLimit = ref(180); // 3 minutes in seconds
// const timeLimit = ref(3); // 3 minutes in seconds

const minutes = computed(() => Math.floor(timeLimit.value / 60));
const seconds = computed(() =>
  (timeLimit.value % 60).toString().padStart(2, "0")
);

const showOtpPopup = ref(false);

const interval = ref(0);

const isButtonDisabled = computed(
  /// ไว้ disable ปุ่มขอหมายเลข otp ใหม่
  () => timeLimit.value <= 0 && total_req_otp.value <= 2
); // เงื่อนไขการ disable button sent otp

// onMounted(() => {
//   startCountdown();
// });

onMounted(() => {
  setTimeout(() => {
      onPostRequestCall();
  }, 2000);
});

function generateOtp() {
  return Math.floor(100000 + Math.random() * 900000).toString();
} //  สร้างเอง ตอนนี้เปลี่ยนเป็น SMS 13092024

const startCountdown = () => {
  /// ไว้นับเวลา
  interval.value = window.setInterval(() => {
    if (timeLimit.value > 0) {
      timeLimit.value--;
    } else {
      clearInterval(interval.value);
    }
  }, 1000);
};

function requestNewOtp() {
  //request generate new otp number
  if (total_req_otp.value < 2 && count_req_otp.value < 5) {
    //check count sent otp
    // otp.value = generateOtp();
    // timeLimit.value = 3; // Reset the timer
    timeLimit.value = 180; // Reset the timer
    count_input_otp.value = 0;
    showOtpPopup.value = false;
    count_req_otp.value += 1;
    chkValidateOtp.value = false;
    onPostRequestCall();
    // startCountdown();
  } else {
    if (total_req_otp.value === 2) {
      popupHeadText.value = " ขอรหัส OTP เกินจำนวนครั้งที่กำหนด";
      popupMessage.value = `ท่านถูกล็อคการขอรหัส OTP 24 ชม. หากต้องต้องการดำเนินการต่อทันที กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
      isPopupVisible.value = true;
    } else {
      // timeLimit.value = 3; // Reset the timer
      timeLimit.value = 180; // Reset the timer
      showOtpPopup.value = false;
      startCountdown();
      count_input_otp.value = 0;
      count_req_otp.value = 0;
      total_req_otp.value += 1;
    }
  }
}

////-------- end run time otp

///////-------------------intut otp start

const numberOfFields = 6;
const otpValues = ref<string[]>(new Array(numberOfFields).fill(""));
const digits = ref<string[]>(Array(numberOfFields).fill(""));

// สร้าง ref สำหรับ input fields
const otpFields = ref<(HTMLInputElement | null)[]>(
  new Array(numberOfFields).fill(null)
);

// ฟังก์ชันจัดการการป้อนข้อมูล
const handleInput = async (index: number) => {
  const currentValue = otpValues.value[index];

  if (/^\d$/.test(currentValue)) {
    // กรณีที่ช่องไม่ใช่ช่องสุดท้ายและมีค่า OTP ที่ถูกต้อง
    if (index < numberOfFields - 1 && otpValues.value[index] !== "") {
      otpFields.value[index + 1]?.focus();
    }

    if (index === 5) {
      chkValidateOtp.value = false;
    }
  } else {
    // ทำให้ช่องป้อนข้อมูลว่าง
    otpValues.value[index] = "";

    if (index === 5) {
      chkValidateOtp.value = true;
    }
  }
};

// ฟังก์ชันจัดการการกดปุ่ม
const handleKeydown = async (index: number, event: KeyboardEvent) => {
  if (event.key === "Backspace" && otpValues.value[index] === "") {
    if (index > 0) {
      otpFields.value[index - 1]?.focus();
    }
  } else if (event.key === "ArrowLeft" && index > 0) {
    otpFields.value[index - 1]?.focus();
  } else if (event.key === "ArrowRight" && index < numberOfFields - 1) {
    otpFields.value[index + 1]?.focus();
  }
};

// ฟังก์ชันจัดการการโฟกัส
const handleFocus = (index: number) => {
  const field = otpFields.value[index];
  if (field) {
    field.setSelectionRange(0, field.value.length);
  }
};

/////////////--------------------end input

////// call api service start

const onPostRequestCall = async () => {
  /// ขอ otp
  let req = {
    context_token: userInfo.value.context_token,
  };
  await onPostRequest(req).then(
    (res: any) => {
      if (res) {
          data_res_otp.value = {
            ...res,
          };
          // count_input_otp.value = 0
          startCountdown(); 
      }else{
        router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code:  '999',
        message: 'check error api'
      }
    });

      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : " + err.value;
      popupMessage.value = "ข้อผิดพลาด " + err.value;
      // router.push({ name: "error" });
    }
  );
};

const showPopup = () => {
  if (total_req_otp.value < 2) {
    digits.value = otpValues.value;
    if (otpValues.value.length === 6) {
      // const otpEntered = digits.value.join("");
      // if (otpEntered === otp.value) {
      //   onPostVerifyTokenCallService();
      // } else
      if (count_input_otp.value < 3) {
        count_input_otp.value += 1;
        message_input_otp.value = `OTP ไม่ถูกต้อง กรุณาตรวจสอบและระบุใหม่อีกครั้ง (${count_input_otp.value} / 3)`;
      } else if (count_input_otp.value === 3) {
        message_input_otp.value = `ท่านระบุ OTP ไม่ถูกต้องครบจำนวนครั้งที่กำหนด กรุณากด "กดอีกครั้ง" เพื่อรับรหัส OTP ใหม่`;
        chkValidateOtp.value = true;
      }
    }
  } else {
    onPostLockCallService();
    // router.push({ name: "P01Component" });
  }
};

const onPostVerifyTokenCallService = async () => {
  /// verify otp
  // verify otp succes next page
  let req = {
    ...data_res_otp.value,
    otp: otpValues.value.join(""),
  };
  await onPostVerify(req).then(
    (res: any) => {
      // console.log(res);
      if (res.is_passed === true) {
        router.push({ name: "P07VerifyOTPSuccess" });
      } else {
        showPopup();
      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : " + err.value;
      popupMessage.value = "ข้อผิดพลาด " + err.value;
      // router.push({ name: "error" });
    }
  );
};

const onPostLockCallService = async () => {
  // check user lock sent request otp
  let req = {
    ...data_res_otp.value,
  };
  await onPostLock(req).then(
    (res: any) => {
      if (res.is_locked === true) {
        popupHeadText.value = " ขอรหัส OTP เกินจำนวนครั้งที่กำหนด";
        popupMessage.value = `ท่านถูกล็อคการขอรหัส OTP 24 ชม. หากต้องต้องการดำเนินการต่อทันที กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
        isPopupVisible.value = true;
        // total_req_otp.value = 0;
        // count_input_otp.value = 0;
        // count_req_otp.value = 0;
        router.push({ name: "P01Consent" });
        handleClose();
        chkValidateOtp.value = true;
      } else {
        ///เงื่อนไขการถูก block
        // popupHeadText.value = " ขอรหัส OTP เกินจำนวนครั้งที่กำหนด";
        // popupMessage.value = `กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
        // isPopupVisible.value = true;
        popupHeadText.value = "เกิดข้อผิดพลาด";
        popupMessage.value = `กรุณาติดต่อเจ้าหน้า SME D Bank หรือโทร 1357 เพื่อปลดล็อคการใช้งาน`;
        isPopupVisible.value = true;
      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : " + err.value;
      popupMessage.value = "ข้อผิดพลาด " + err.value;
      // router.push({ name: "error" });
    }
  );
};

// end call api service

const handleClose = () => {
  // router.push({ name: "P06Settings" });
  // ตรวจสอบว่ามีการโหลด LIFF สำเร็จก่อนที่จะปิดหน้าต่าง
  // if (liff.isInClient()) {
  // liff.closeWindow(); // ปิดแอปพลิเคชัน เรียกใบ้ได้เฉพาะบน line เท่านั้น
  window.postMessage("closeWebView", "*");
  window.close();
  // } else {
  // router.push({ name: "P01Consent" });
  // console.error("Not running inside LINE app.");
  // }
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

a {
  color: blue;
  font-weight: bolder;
  text-decoration: underline;
}

.fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  background-color: white;
  padding: 20px;
  font-size: 18px;
}

.p-text-req {
  margin-top: 10px;
  cursor: pointer;
  font-size: 30px;
  color: blue;
  text-decoration: underline;
}

.hide-txt {
  display: grid;
  grid-template-rows: 500px 1fr;
}

.load-style {
  min-height: 50vh;
}

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
