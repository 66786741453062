import axiosInstance from "./axiosURL";


export const onPostRequest = async (obj: any) => {  //
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        await axiosInstance.post(`/api/otp/request`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }

export const onPostVerify = async (obj: any) => {  //
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        
        await axiosInstance.post(`/api/otp/verify`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }
  

export const onPostLock = async (obj: any) => {  //
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        
        await axiosInstance.post(`/api/otp/lock`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }
  