import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/img/error_img.png'


const _hoisted_1 = { class: "setbody" }
const _hoisted_2 = { class: "logo" }

import { ref } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'P00ErrorPage',
  setup(__props) {

const route = useRoute();
const errorCode = ref(route.params.code || 'Unknown Error');
const errorMessage = ref(route.params.message || 'An unexpected error occurred.');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "txthead" }, "เกิดข้อผิดพลาดในระบบ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        width: "250",
        height: "125",
        alt: "SME Development Bank"
      }, null, -1)),
      _createElementVNode("h1", null, "Error " + _toDisplayString(errorCode.value), 1),
      _createElementVNode("p", null, _toDisplayString(errorMessage.value), 1)
    ]),
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"content\" data-v-54081b01><article class=\"column1\" data-v-54081b01><p class=\"info-text\" data-v-54081b01> สอบถามเพิ่มเติมกรุณาติดต่อเจ้าหน้าที่สาขา SME D Bank ทั่วประเทศ หรือ <a href=\"tel:1357\" data-v-54081b01> SME D Bank Call Center โทร. 1357 </a></p></article></div><div class=\"fixed-footer\" data-v-54081b01></div>", 2))
  ]))
}
}

})