<template>
  <div class="container">
    <h2><b>ระบุข้อมูลเพื่อยืนยันตัวตน</b></h2>
    <p class="pleft small">ระบุเลขบัตรประจำตัวประชาชน หรือเลขนิติบุคคล</p>
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <img src="../assets/img/c01.png" alt="icon" />
          </span>
        </div>
        <input
          type="text"
          id="numberInput"
          maxlength="13"
          class="form-control"
          v-model="numberInput"
          @input="handleInput"
          placeholder="กรุณาระบุหมายเลข"
          inputmode="numeric"
        />
      </div>
    </div>
    <Buttons
      :primary="primary"
      :label="label"
      @click="handleSubmit"
      :disabled="!isButtonEnabled"
      :type="type"
    />

    <PopUp
      :isVisible="isPopupVisible"
      :message="popupMessage"
      :headText="popupHeadText"
      @close="isPopupVisible = false"
    />
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
import PopUp from "@/components/pop_up/PopUp.vue";
import { onPostEnquiry } from "@/services/authService";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo);

const numberInput = ref<string>("");

/////// start pop up message
const isPopupVisible = ref<boolean>(false);
const popupMessage = ref<string>("");
const popupHeadText = ref<string>("");
/////   end pop up message

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ถัดไป");

const isButtonEnabled = ref<boolean>(false);

const type = ref("button");

///--------- button end

const handleInput = () => {
  if (numberInput.value.length >= 13) {
    isButtonEnabled.value = true;
  } else {
    isButtonEnabled.value = false;
  }
};

const handleSubmit = async () => {
  const data_req = {
    context_token: userInfo.value.context_token,
    cid: numberInput.value,
  };
  if (
    (data_req.context_token !== "" || data_req.context_token !== null) &&
    numberInput.value.length >= 13
  ) {
    await onCallPostEnquiry(data_req);
  }
};

const onCallPostEnquiry = async (value: any) => {
  let req = {
    ...value,
  };
  await onPostEnquiry(req).then(
    (res: any) => {
      if (res.limited !== true) {
        if (res.eligible === true) {
          store.dispatch("updateUserInfo", res);
          router.push({ name: "P05RequestOTP" });
        } else {
          // popupMessage.value = "ไม่พบข้อมูล";
          popupHeadText.value = res.reason.message;
          popupMessage.value = res.reason.detail;
          isPopupVisible.value = true;
        }
      } else if (res.limited === true) {
        router.push({ name: "error-enquiry" });
      }
    },
    function (err: any) {
      router.push({ 
      name: 'error', // ชื่อของหน้า error
      params: { 
        code: err.response?.status || '500',
        message: err.response?.data?.message || 'Something went wrong!'
      }
    });
      popupHeadText.value = "เกิดข้อผิดพลาด : "+err.value;
      popupMessage.value = "ข้อผิดพลาด "+err.value;
      router.push({name:"error"})
    }
  );
};
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
