import { createStore } from "vuex";

// ประเภทของข้อมูลใน Store
interface UserInfo {
  context_token: string;
  is_done: boolean;
  [key: string]: any; // สามารถขยายประเภทข้อมูลได้ตามต้องการ
}

// Define your state types
interface State {
  userInfo: UserInfo | null;
}

// กำหนดสถานะเริ่มต้นของ Store โดยดึงข้อมูลจาก localStorage ถ้ามี
const state: State = {
  userInfo: JSON.parse(localStorage.getItem("userInfo") || "null"), // ดึงข้อมูลจาก localStorage ถ้ามี
};

export const store = createStore<State>({
  state,
  getters: {
    getUserInfo: (state) => state.userInfo, // Getter เพื่อดึงข้อมูล object
  },
  mutations: {
    SET_USER_INFO(state, userInfo: UserInfo) {
      state.userInfo = userInfo; // บันทึกข้อมูลใหม่ใน state
      localStorage.setItem("userInfo", JSON.stringify(userInfo)); // บันทึกข้อมูลลง localStorage
    },
    CLEAR_USER_INFO(state) {
      state.userInfo = null; // ลบข้อมูลใน state
      localStorage.removeItem("userInfo"); // ลบข้อมูลออกจาก localStorage
    },
  },
  actions: {
    updateUserInfo({ commit }, userInfo: UserInfo) {
      commit("SET_USER_INFO", userInfo); // Action เพื่ออัพเดตข้อมูลใน Store และ localStorage
    },
    clearUserInfo({ commit }) {
      commit("CLEAR_USER_INFO"); // Action เพื่อเคลียร์ข้อมูลใน Store และ localStorage
    },
  },
});
