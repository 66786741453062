import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/check-icon.png'


const _hoisted_1 = { class: "content" }

import Buttons from "@/components/button/Buttons.vue";
// import { initializeLiff } from "@/services/liff";
// import liff from "@line/liff";
import { ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'P11SeccessCancelReceipt',
  setup(__props) {

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block btnfoot");

const label = ref("ปิด");

const disable = ref<boolean>(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  // router.push("/views");
  //   liff.closeWindow(); // ปิดแอปพลิเคชัน
  window.postMessage("closeWebView", "*");
  window.close();
};
// initializeLiff();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"status-icon\" data-v-46491306><img src=\"" + _imports_0 + "\" alt=\"Check Icon\" width=\"100px\" height=\"100px\" data-v-46491306></div><h2 class=\"green\" data-v-46491306>ดำเนินการเรียบร้อยแล้ว</h2><p class=\"small\" data-v-46491306>ยกเลิกใช้บริการ SME Connect</p><p class=\"small\" data-v-46491306>จะมีผลภายใน 24 ชม.</p><div class=\"fixed-footer\" data-v-46491306></div>", 5)
  ])))
}
}

})