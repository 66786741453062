import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/logo.png'


const _hoisted_1 = { class: "setbody" }
const _hoisted_2 = { class: "content" }

import Buttons from "@/components/button/Buttons.vue";
import PopUp from "@/components/pop_up/PopUp.vue";
import { onPostConsent, onPostVerifyToken } from "@/services/authService";
// import liff from "@line/liff";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'P01Consent',
  setup(__props) {

const router = useRouter();

const route = useRoute();

const getCtk = computed(() => route.query.ctk || "");

const store = useStore();

const userInfo = computed(() => store.getters.getUserInfo); ///ใช้ชั่วคราว

/////// start pop up message
const isPopupVisible = ref<boolean>(false);
const popupMessage = ref<string>("");
const popupHeadText = ref<string>("");
/////   end pop up message

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ระบุข้อมูลเพื่อยืนยันตัวตน");

const disable = ref(false);

const type = ref("button");

///--------- button end

// onMounted(() => {
//   localStorage.removeItem("userInfo"); // เคลียร์ข้อมูลก่อนเริ่มใช้งานแอป
//   store.dispatch("clearUserInfo"); // เรียก action ของ Vuex เพื่อเคลียร์ข้อมูลใน Store
// });

const goToPage = async () => {
  const data_req = {
    context_token:
      getCtk.value !== "" ? getCtk.value : userInfo.value.context_token,
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoidGVzdCIsImV4cCI6MTcyNjQ4MTE4MywiaWF0IjoxNzI2NDc3NTgzfQ.y4nb_LwwgmVcjaSzu9NIUKFn0MDB2200gE1qNnPUHw4",
    // context_token:
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMTIzMTIzMTIzMTIzMTIiLCJleHAiOjE3NTc3MzM0OTcsImlhdCI6MTcyNjE5NzQ5N30.kw4AShHYqhqa3vF214XUfq1d2T6SRJfV-JS5tfe1tws",
  };
  await onPostVerifyToken(data_req).then(
    (res: any) => {
      // setLoading(false);
      if (!res.error_message) {
        if (res.is_done === true) {
          onPostConsentCall(res);
        } else {
          popupHeadText.value = "แจ้งเตือน";
          popupMessage.value = "ไม่พบข้อมูล";
        }
      } else {
        popupHeadText.value = "แจ้งเตือน";
        popupMessage.value = "ไม่พบข้อมูล";
        isPopupVisible.value = true;
      }
    },
    function (err: any) {
      popupHeadText.value = "เกิดข้อผิดพลาด : "+err.value;
      popupMessage.value = "ข้อผิดพลาด "+err.value;
      router.push({name:"error"})
    }
  );
};

const onPostConsentCall = async (obj: any) => {
  let req = {
    ...obj,
  };
  await onPostConsent(req).then(
    (res: any) => {
      if (!res.error_message) {
        if (res.is_done === true) {
          store.dispatch("updateUserInfo", res); // บันทึกข้อมูลใหม่ลง Store และ localStorage
          router.push({
            name: "P03ConsentVerify",
          });
        } else {
          popupHeadText.value = "แจ้งเตือน";
          popupMessage.value = "ไม่พบข้อมูล";
        }
      } else {
        popupHeadText.value = "แจ้งเตือน";
        popupMessage.value = "ไม่พบข้อมูล";
        isPopupVisible.value = true;
      }
    },
    function (err: any) {
      popupHeadText.value = "เกิดข้อผิดพลาด : "+err.value;
      popupMessage.value = "ข้อผิดพลาด "+err.value;
      router.push({name:"error"})
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "logo" }, [
      _createElementVNode("img", {
        src: _imports_0,
        width: "150px",
        alt: "SME Development Bank"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("article", { class: "column1" }, [
        _createElementVNode("p", { class: "txthead" }, "SME Connect"),
        _createElementVNode("div", { class: "text-detail" }, [
          _createElementVNode("p", null, "สมัครขอใบแจ้งหนี้และใบเสร็จผ่าน Line"),
          _createElementVNode("p", null, "สำหรับลูกค้านิติบุคคล")
        ])
      ], -1)),
      _createVNode(Buttons, {
        primary: primary.value,
        label: label.value,
        onClick: goToPage,
        disabled: disable.value,
        type: type.value
      }, null, 8, ["primary", "label", "disabled", "type"])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fixed-footer" }, [
      _createTextVNode(" อ่านวิธีที่เราเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล และเข้าใจสิทธิของคุณที่ "),
      _createElementVNode("a", { href: "https://www.smebank.co.th/privacy-policy/" }, "ประกาศนโยบายความเป็นส่วนตัว")
    ], -1)),
    _createVNode(PopUp, {
      isVisible: isPopupVisible.value,
      message: popupMessage.value,
      headText: popupHeadText.value,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (isPopupVisible.value = false))
    }, null, 8, ["isVisible", "message", "headText"])
  ]))
}
}

})