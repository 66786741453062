import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/img/check-icon.png'


import { ref } from "vue";
import { useRouter } from "vue-router";
import Buttons from "@/components/button/Buttons.vue";
// import liff from "@line/liff";
// import { initializeLiff } from "../services/liff";


export default /*@__PURE__*/_defineComponent({
  __name: 'P07VerifyOTPSuccess',
  setup(__props) {

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block");

const label = ref("ปิด");

const disable = ref(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  // router.push({ name: "P06Settings" });
  // ตรวจสอบว่ามีการโหลด LIFF สำเร็จก่อนที่จะปิดหน้าต่าง
  // if (liff.isInClient()) {
  //   // console.log("🚀 ~ handleClose ~ liff.isInClient():", liff.isInClient());
  //   window.close();
  //   liff.closeWindow(); // ปิดแอปพลิเคชัน เรียกใบ้ได้เฉพาะบน line เท่านั้น
  // } else {
  //   liff.closeWindow(); // ปิดแอปพลิเคชัน เรียกใบ้ได้เฉพาะบน line เท่านั้น
    window.postMessage("closeWebView", "*");
    window.close();
    // router.push({ name: "P01Consent" });

    // console.error("Not running inside LINE app.");
  // }
};
// initializeLiff();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"content\" data-v-a9730304><div class=\"status-icon\" data-v-a9730304><img src=\"" + _imports_0 + "\" alt=\"Check Icon\" width=\"100px\" height=\"100px\" data-v-a9730304></div><h2 class=\"green\" data-v-a9730304>ดำเนินการเรียบร้อยแล้ว</h2><p class=\"small\" data-v-a9730304>บริการขอใบแจ้งหนี/ใบเสร็จ</p><p class=\"small\" data-v-a9730304>มีผลทันทีหลังจากสมัครใช้งาน SME D Bank สำเร็จ</p></div><div class=\"fixed-footer\" data-v-a9730304></div>", 2)
  ])))
}
}

})