<template>
  <div class="setbody">
    <p class="txthead">เกิดข้อผิดพลาดในระบบ</p>
    <!-- <p class="txterr">Error 404</p> -->
    <div class="logo">
      <img
        src="../../assets/img/error_img.png"
        width="250"
        height="125"
        alt="SME Development Bank"
      />
      <h1>Error {{ errorCode }}</h1>
      <p>{{ errorMessage }}</p>
    </div>
    <div class="content">
      <article class="column1">
        <p class="info-text">
          สอบถามเพิ่มเติมกรุณาติดต่อเจ้าหน้าที่สาขา SME D Bank ทั่วประเทศ หรือ
          <a href="tel:1357"> SME D Bank Call Center โทร. 1357 </a>
        </p>
      </article>
    </div>
    <div class="fixed-footer"></div>
    <!-- <PopUp
      :isVisible="isPopupVisible"
      :message="popupMessage"
      :headText="popupHeadText"
      @close="isPopupVisible = false"
    /> -->
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const errorCode = ref(route.params.code || 'Unknown Error');
const errorMessage = ref(route.params.message || 'An unexpected error occurred.');
</script>

<style scoped>
.error-page {
  text-align: center;
  margin-top: 50px;
}

.txthead {
  font-size: 4vh;
  margin-top: 3vh;
  margin-bottom: 0;
  color: black;
}
.txterr {
  font-size: 3vh;
  color: black;
}
.logo {
  margin-top: 1vh;
}
.info-text {
  text-align: center;
}
</style>
