import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

import { computed } from "vue";



interface Props {
  label: string;
  primary: string;
  disabled: boolean;
  type: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Buttons',
  props: {
    label: {},
    primary: {},
    disabled: { type: Boolean },
    type: {}
  },
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([`btn ${_ctx.primary}`]),
    type: _ctx.type,
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.label), 11, _hoisted_1))
}
}

})