import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import P01Component from "@/page/P01Component.vue";
import P02Enquiry from "@/page/P02Enquiry.vue";
import P03ConsentVerify from "@/page/P03ConsentVerify.vue";
import P04SettingAutoSuccess from "@/page/P04SettingAutoSuccess.vue";
import P05RequestOTP from "@/page/P05RequestOTP.vue";
import P06Settings from "@/page/P06Settings.vue";
import P07VerifyOTPSuccess from "@/page/P07VerifyOTPSuccess.vue";
import P08AgeeReceipt from "@/page/P08AgeeReceipt.vue";
import P09AgeeReceipt from "@/page/P09AgeeReceipt.vue";
import P11SeccessCancelReceipt from "@/page/P11SeccessCancelReceipt.vue";
import P08AgeeReceipt_V2 from "@/page/P08AgeeReceipt_V2.vue";
import { store } from "@/stores/store";
import P01Consent from "@/page/P01Consent.vue";
import P06Settings_V2_setting_Cancel from "@/page/P06Settings_V2_setting_Cancel.vue";
import P00RoutePage from "@/page/P00RoutePage.vue";
import P00ErrorPage from "@/page/error_pages/P00ErrorPage.vue";
import P00ErrorPage_Enquiry from "@/page/error_pages/P00ErrorPage_Enquiry.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/views",
    // path: "/views",
    name: "P00RoutePage",
    // component: P01Consent,
    component: P00RoutePage,
    meta: { title: "กำลังโหลด..." }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views",
    name: "P01Consent",
    component: P01Consent,
    meta: { title: "สมัครใช้งาน" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/verify",
    name: "P02Enquiry",
    component: P02Enquiry,
    meta: { title: "สมัครใช้งาน" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/terms",
    name: "P03ConsentVerify",
    component: P03ConsentVerify,
    meta: { title: "ข้อกำหนดและเงื่อนไข" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/setting-connect-success",
    name: "P04SettingAutoSuccess",
    component: P04SettingAutoSuccess,
    meta: { title: "ข้อกำหนดและเงื่อนไข" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/verify-otp",
    name: "P05RequestOTP",
    component: P05RequestOTP,
    meta: { title: "ยืนยันตัวตน" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/setting-connect",
    name: "P06Settings",
    // component: P06Settings,
    component: P06Settings_V2_setting_Cancel,
    meta: { title: "ตั้งค่า" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/verify-otp-success",
    name: "P07VerifyOTPSuccess",
    component: P07VerifyOTPSuccess,
    meta: { title: "ตั้งค่า" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/agee-receipt",
    name: "P08AgeeReceipt",
    component: P08AgeeReceipt,
    meta: { title: "ตั้งค่า" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/agee-success",
    name: "P09AgeeReceipt",
    component: P09AgeeReceipt,
    meta: { title: "ตั้งค่า" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/views/seccess-cancel",
    name: "P11SeccessCancelReceipt",
    component: P11SeccessCancelReceipt,
    meta: { title: "ตั้งค่า" }, // กำหนด title ของหน้า Home
  },
  {
    path: "/error",
    name: "error",
    component: P00ErrorPage,
    meta: { title: "เกิดข้อผิดพลาดในระบบ" },
  },
  {
    path: "/error-enquiry",
    name: "error-enquiry",
    component: P00ErrorPage_Enquiry,
    meta: { title: "เกิดข้อผิดพลาดในระบบ" },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/error", // เปลี่ยนไปยังหน้า Error หากไม่พบเส้นทางที่ต้องการ
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL), //ใช้เพื่อส่งค่าไปเช็คก่อนไปหน้าอื่น ๆ
  routes,
});

// ใช้ navigation guard เพื่อเปลี่ยน title ของหน้า
router.beforeEach((to, from, next) => {
  // เปลี่ยน title ตาม meta ของแต่ละหน้า
  document.title = (to.meta.title as string) || "Default Title";

  // เปลี่ยน favicon ตาม meta.icon ของแต่ละหน้า
  const iconLink =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  iconLink.setAttribute("rel", "icon");
  iconLink.setAttribute("type", "image/png");
  iconLink.setAttribute("href", "../src/assets/img/logo.png"); // เปลี่ยน icon D:\Workspace\connect\views\src\assets\img\logo.png
  document.head.appendChild(iconLink);

  next();
});

// // Navigation Guard เพื่อเช็คเงื่อนไขก่อนเข้าหน้าหลัก
// router.beforeEach((to, from, next) => {
//   //   // เช็คว่าหน้านี้ต้องการการตรวจสอบหรือไม่
//   //   if (to.meta.requiresAuth) {
//   //     // ดึงข้อมูลจาก Store มาเช็ค
//   const isValid =
//     store.getters.getUserInfo !== undefined &&
//     store.getters.getUserInfo.is_done === true; // ตัวอย่างเช็คว่ามี userId หรือไม่

//   if (isValid) {
//     next(); // ให้ผ่านไปหน้าหลัก
//   } else if (isValid && to.path === "/error") {
//     next(); // อนุญาตให้เข้าถึงหน้า error ได้ถ้าอยู่ในเส้นทาง /error แล้ว
//   } else {
//     next(); // อนุญาตให้เข้าถึงหน้าอื่น ๆ ได้ปกติ
//   }
//   // } else {
//   //   next(); // ให้ผ่านได้เลยถ้าไม่ต้องเช็ค
//   // }
// });

export default router;
