<template>
  <div class="content">
    <div class="status-icon">
      <img
        src="../assets/img/check-icon.png"
        alt="Check Icon"
        width="100px"
        height="100px"
      />
    </div>
    <h2 class="green">ดำเนินการเรียบร้อยแล้ว</h2>
    <p class="small">ยกเลิกใช้บริการ SME Connect</p>
    <p class="small">จะมีผลภายใน 24 ชม.</p>
    <div class="fixed-footer">
      <!-- <Buttons
        :primary="primary"
        :label="label"
        @click="handleClose"
        :disabled="disable"
        :type="type"
      /> -->
    </div>
    <!-- <button class="btn btn-primary btn-block btnfoot" @click="handleClose">
      ปิด
    </button> -->
  </div>
</template>

<script lang="ts" setup>
import Buttons from "@/components/button/Buttons.vue";
// import { initializeLiff } from "@/services/liff";
// import liff from "@line/liff";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

///--------- button start

const primary = ref("btn-primary btn-block btnfoot");

const label = ref("ปิด");

const disable = ref<boolean>(false);

const type = ref("button");

///--------- button end

const handleClose = () => {
  // router.push("/views");
  //   liff.closeWindow(); // ปิดแอปพลิเคชัน
  window.postMessage("closeWebView", "*");
  window.close();
};
// initializeLiff();
</script>

<style scoped>
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/style.css";

p {
  align-items: center;
}
.small {
  margin-top: 3vh;
  font-size: 26px;
  line-height: 0.25em;
  align-items: center;
}

/* คุณสามารถเพิ่มสไตล์ที่กำหนดเองได้ที่นี่ */
</style>
