import axiosInstance from "./axiosURL";


export const onPostAuto_sendSettings= async (obj: any) => {  //auto sent 
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        await axiosInstance.post(`/api/member/settings/auto_send`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }

export const onPostSettings= async (obj: any) => {  //
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        await axiosInstance.post(`/api/member/settings`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }

export const onPostUpdateSettings= async (obj: any) => {  //Update
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        await axiosInstance.post(`/api/member/settings/update`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }

export const onPostRemoveSettings= async (obj: any) => {  //remove
    return new Promise(async (resolve, reject) => {
      try {
        // let authorization = await axiosInstance();
        await axiosInstance.post(`/api/member/remove`, obj)
          .then(res => {
            resolve(res.data);
          }).catch((error) => {
            reject(error);
          });
      }
      catch (err) {
        reject(err);
      }
      finally {
  
      }
    })
  }